import React from 'react';
import '../style/ProgramHighlight.css';
import { MdCastForEducation, MdDesignServices, MdLocalLibrary, MdMenuBook, MdOutlineLocalLibrary } from 'react-icons/md';
import { IoMdTrophy } from 'react-icons/io';

const ProgramHighlight = () => {
  return (
    <div>
      <section>
      
        <div className="container text-center">
        <div className="bgdesign py-4">
        <h1 className='display-6 fw-bold text-center'>Program <span style={{ color: "#107898" }}>Highlight</span> </h1>
        </div>
        <div className="container ">
          <div className="row rows">
          <div className="col-lg  col-md-6  col-sm-6 mb-4">
          <div className="card  cardb">
            <div className="card-body  col-10">
                  <div className="Back"> 
                  <MdLocalLibrary size={40} className='internship-icon'/>
                  </div>
                  <div className="cardtext pt-4">
                  Hands-On Experience
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-6 mb-4">
            <div className="card  cardb">
            <div className="card-body col-11">
                  <div className="Back"> 
                  <MdCastForEducation size={40} className='internship-icon'/>
                  </div>
                  <div className="cardtext pt-4">
                  Comprehensive Training
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-6 mb-4">
            <div className="card  cardb">
            <div className="card-body ">
                  <div className="Back"> 
                  <MdDesignServices
                  size={40} className='internship-icon'/>
                  </div>
                  <div className="cardtext pt-4 pe-1">
                  Skills and Toolset Development
                  </div>
              </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-6 mb-4">
            <div className="card  cardb">
            <div className="card-body  col-8">
                  <div className="Back"> 
                  <IoMdTrophy size={40} className='internship-icon'/>
                  </div>
                  <div className="cardtext pt-4">
                  Professional Growth
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-6 mb-4">
            <div className="card  cardb">
            <div className="card-body  col-8">
                  <div className="Back"> 
                  <MdMenuBook size={40} className='internship-icon'/>
                  </div>
                  <div className="cardtext pt-4">
                  Flexible Learning
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col">
          <div>
            <button className="btn Cbutton my-3 mb-5">APPLY NOW</button>
           
            </div>
          </div>
          </div>
        </div>
        
      </section>
      
    </div>
  );
}

export default ProgramHighlight;
