import React, { useState } from 'react'

const ServiceFaq = () => {
     // accoredance data
  const accordionData = [
    {
      title: "How long will it take to get the desired results?",
      content: "The timeline for results varies based on the service and specific project goals. For example, web development projects typically take 4 to 8 weeks, while digital marketing efforts may take 3 to 6 months to show significant results."
    },
    {
      title: "Should I hire an in-house team or an agency?",
      content: "Hiring an agency like ours provides you with a team of diverse experts without the overhead costs associated with an in-house team. We offer specialized skills and experience to deliver high-quality solutions tailored to your business needs."
    },
    {
      title: "How long does it take to build a website?",
      content: "The timeline for building a website depends on the project's complexity and requirements. Typically, a standard website can take between 4 to 8weeks, including planning, design, development, and testing."
    },
    {
      title: "Will my website be mobile-friendly?",
      content: "Yes, all websites we develop are fully responsive, ensuring they look and function well on all devices, including smartphones and tablets."
    },
    {
      title: "What is your success rate?",
      content: "Our success rate is high, with numerous satisfied clients and completed projects. We measure success by the achievement of client goals and theimpact our solutions have on their business growth."
    }
  ];
const [openAccordion, setOpenAccordion] = useState(null);
const handleToggle = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };
  return (
    <div className='container '>
    <div className='text-center py-5'>
    <h1 className='display-6 fw-bold '>FAQs</h1>
    </div>
    <div className='container px-4'>
          <div id="accordion" className='px-md-4'>
      {accordionData.map((item, index) => (
        <div className=" card-faqs mb-3  accordion-body card-body-faq" key={index}>
          <div className="" id={`heading${index}`}>
            <h6
              className="mb-0 accordion-header p-3"
              data-toggle="collapse"
              data-target={`#collapse${index}`}
              aria-expanded={openAccordion === index}
              aria-controls={`collapse${index}`}
              onClick={() => handleToggle(index)}
            >
              {item.title}
              <span
                className="icon"
                style={{
                  padding: '0 5px',
                  marginLeft: '10px'
                }}
              >
                {openAccordion === index ? '-' : '+'}
              </span>
            </h6>
          </div>
          <div
            id={`collapse${index}`}
            className={`collapse ${openAccordion === index ? 'show' : ''}`}
            aria-labelledby={`heading${index}`}
            data-parent="#accordion"
          >
            <div className="card-body-faq accordion-body px-3 py-2">
              {item.content}
            </div>
          </div>
        </div>
      ))}
    </div>
    </div>
    </div>
  )
}

export default ServiceFaq