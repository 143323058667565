import React, { useState } from 'react';
import '../style/Services1.css';
import simg2 from '../assets/ITSolution1.png';
import simg1 from '../assets/Web_solution1.png';
import simg3 from '../assets/App_development1.png';
import simg4 from '../assets/Graphic_deisgn1.png';
import simg5 from '../assets/Marketing_solution1.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slideshow from './Slideshow';
import Navbar from './Navbar';
import { GoArrowUpRight } from 'react-icons/go';
import { Link } from 'react-router-dom';
import ServiceGoal from './ServiceGoal';
import Faq from './Faq';
import ServiceFaq from './ServiceFaq';
AOS.init();
export default function Services1() {
  const service = [
    {
      img: simg1,
      title:"Web Development",
      subTitle:"Building Websites That Work",
      content:"At Inphynous, we create responsive, SEO-friendly websites that attract visitors and convert them into loyal customers.Our web development servicesensure your site is visually appealing, easy to navigate, and highly functional.",
      path:"./home"
    },
    {
      img: simg1,
      title:"E-commerce Solutions",
      subTitle:"Helping Your Online Store Succeed",
      content:"We provide a complete range of e-commerce services to help you set up and grow your online store. From custom development to secure payment systems and mobile optimization, we ensure your platform is strong and scalable.",
      path:"./home"
    }, {
      img: simg3,
      title:"App Development",
      subTitle:"Designing Apps That Engage",
      content:"Our team develops mobile apps that are intuitive and keep users engaged. We focus on creating apps that are functional and interesting, helping your business stay connected with its customers.",
      path:"./home"
    }, {
      img: simg5,
      title:"Digital Marketing",
      subTitle:"Boosting Your Online Presence",
      content:"Our digital marketing services are crafted to increase your online visibility, drive traffic, and boost sales. We design marketing strategies that align with your business goals and deliver measurable results.",
      path:"./home"
    }, {
      img: simg1,
      title:"AI & Machine Learning",
      subTitle:"AI and Machine Learning",
      content:"Our AI and machine learning services unlock new opportunities and enhance efficiency. From predictive analytics to natural language processing, we help you leverage AI to innovate and grow.",
      path:"./home"
    }, {
      img: simg1,
      title:"Cybersecurity Solutions",
      subTitle:"Keeping Your Business Safe Online",
      content:"Our cybersecurity services protect your business from online threats. We offer comprehensive solutions, including threat detection, vulnerability assessments, and data protection, to keep your operations secure.",
      path:"./home"
    },
    {
      img: simg2,
      title:"IT Solutions",
      subTitle:"Improving Efficiency with IT Services",
      content:"We offer IT solutions that streamline your operations and increase efficiency. Our services include cloud computing, data analytics, and custom software development, ensuring your technology supports your business needs.",
      path:"./home"
    },
    {
      img: simg4,
      title:"Design Solutions",
      subTitle:"Creating Designs That Stand Out",
      content:"Our design team creates visuals that clearly communicate your message and enhance your brand. From graphic design to UI/UX design, we ensure your digital assets are attractive and user-friendly.",
      path:"./home"
    },
    {
      img: simg1,
      title:"Cloud Computing",
      subTitle:"Flexible IT Infrastructure",
      content:"Leverage the cloud to improve scalability and reduce costs. Our cloud computing services include migration, management, and optimization, ensuring you get the most out of your cloud investment.",
      path:"/home"
    }
   
  ];
  return (
    <div className='main mx-auto'>
      <div className='servicebg'>
        <div className='sticky-top topn d-none d-md-block'>      <Navbar />
        </div>
        <div className='container text-light'>
          <div className='pt-5 d-none d-md-block'>
            
            <div className='row pt-5'>
              <div className='col-lg-6 mt-2'>
                <h1 className='display-4 fw-bold'> Services</h1>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-7 text-wrap'>
                <p style={{ fontSize: "22px" }}>Inphynous is the leading solution to help enterprises know, protect, and control
                  their data wherever it goes.</p>
              </div>
            </div>
            <div className='row mt-3 pb-4'>
              <div className='col-lg-6'>
                <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center' }}>
                  Get Started
                </button>
              </div>
            </div>
          </div>
          
          <div className='d-block d-md-none'>
            <div className='row pt-4'>
              <div className='col-lg-6 mt-2'>
                <h1 className='display-4 fw-bold'>Services</h1>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-7 text-wrap'>
                <p style={{ fontSize: "22px" }}>Inphynous is the leading solution to help enterprises know, protect, and control
                  their data wherever it goes.</p>
              </div>
            </div>
            <div className='row mt-3 pb-4'>
              <div className='col-lg-6'>
                <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center' }}>
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='' >
        <div className='container text-center mt-4 text-wrap'>
          <h1 className='display-6 fw-bold'>Our <span className='c-blue-green'>Services </span></h1>
          <p className='text-18 px-2 px-md-5 '>At Inphynous, we’re here to help your business grow with our wide range of services. Whether you need a new 
website, an online store, a mobile app, or help with digital marketing, we’ve got the expertise to make it happen. 
Our focus is on providing practical and effective solutions that meet your unique needs.</p>
        </div>
      </div>
        <div className='container  text-wrap'>
        <div className='row py-5 px-2'>
        {service.map((services, index) => (
        <div className='col-lg-4 mb-5 '>
          <div className=' card-services bg-white rounded-4 position-relative card-services1'>
           <div className='d-flex justify-content-center service-hov1'> <div className=' rounded-bottom-5 service-hov '></div></div>
              <div className='d-flex justify-content-center pt-4 px-3'>
                <img src={services.img} alt="Image" className='img-fluid px-5'style={{height:'160px'}} />
              </div>
              <h2 className='text-center fw-bold c-dark-blue pt-3 px-3'>{services.title}</h2>
              <p className='text-18 c-blue-green fw-semibold text-center'>{services.subTitle}</p>
              <p className='text-center text-18 px-3'>{services.content}</p>
              <div className='text-center position-absolute service-btn d-md-block d-none'>
                   <button className='btn btn-dark rounded-pill text-light'><Link className='text-decoration-none text-white'>Know More </Link> <GoArrowUpRight size={20} /></button>
              </div> 
              <div className='text-center  d-md-none d-block'>
                   <button className='btn btn-dark rounded-pill text-light my-3'><Link className='text-decoration-none text-white'>Know More </Link> <GoArrowUpRight size={20} /></button>
              </div> 
            </div>
            </div> 
          ))}
        </div>
      </div>
        <div className='  bg-white'>
        <ServiceGoal/>
        </div>
        <div>
          <ServiceFaq/>
        </div>
    </div>
  );
}
