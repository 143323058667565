import React from 'react';

const InternshipRole = () => {
  return (
    <div className='container'>
      <h1 className=' display-6 fw-bold text-center pb-5'> Internship Opportunities at<span style={{ color: "#107898" }}> Inphynous Technology Solutions</span> </h1>
      <div className='row justify-content-center text-center px-5'>
      <div className='col-md-5 roles me-4 pt-4'>
        <h3 className='pb-4'>Digital Marketing Roles</h3>
        <div className=' d-flex justify-content-evenly text-center'>
          <ul className=' text-start'>
            <li className='pb-2'>Meta Ads Specialist</li>
            <li className='pb-2'>Google Ads Specialist</li>
            <li className='pb-2'>LinkedIn Ads Specialist</li>
            <li className='pb-2'>SEO Specialist</li>
            <li className='pb-2'>Content Marketing Specialist</li>
            </ul>
            <ul className='text-start'>
            <li className='pb-2'>Social Media Manager</li>
            <li className='pb-2'>PPC Specialist</li>
            <li className='pb-2'>Email Marketing Specialist</li>
            <li className='pb-2'>Influencer Marketing Specialist</li>
            <li className='pb-2'>Analytics and Data Science Specialist</li>
          </ul>
          </div>
      </div>
      <div className='col-md-5 roles ms-4 pt-4'>
        <h3 className='pb-4'>Web Development Roles</h3>
        <div className=' d-flex justify-content-evenly text-center'>
        <ul className='text-start'>
            <li className='pb-2 '>Front-End Developer</li>
            <li className='pb-2'>Back-End Developer</li>
            <li className='pb-2'>Full-Stack Developer</li>
            <li className='pb-2'>UI/UX Designer</li>
            </ul>
            <ul className='text-start'>
            <li className='pb-2'>Graphic Designer</li>
            <li className='pb-2'>Web Designer</li>
            <li className='pb-2'>Mobile App Developer</li>
            <li className='pb-2'>DevOps Specialist</li>
           
            </ul>
            </div>
      </div>
      </div>
      </div>
  );
}

export default InternshipRole;
