import React from "react";

const InternshipExperience = () => {

      return (
            <div className="container px-md-4 px-3">
                  <div className="row">
                        <div className="col-md-6">
                              <h5 style={{ color: '#107898' }}>The Digital Marketing Internship Plan </h5>
                              <h1 className='display-6 fw-bold '> Get Ready for Real-World Challenges with <span style={{ color: "#107898" }}>Real-World Experiences!</span> </h1>

                        </div>
                  </div>
                  <div className='row py-3 mt-3'>
                        <div className='col-md-4'>
                              <div className='pe-md-4 pe-0'>
                                    <div className='d-flex'>
                                          <h5 className='rounded-circle mt-1 pt-1 px-2 mb-0 text-white text-center' style={{ backgroundColor: "#107898", height: "35px", width: "35px" }}>1</h5>
                                          <h5 className='ps-3 pt-2' >Practical Skills </h5>
                                    </div>
                                    <p className='pt-2'>Internship provide hands-on training where students apply theoretical knowledge to real-world tasks. They learn specific job-related skills like campaign management, data analysis, social media marketing, and SEO optimization, preparing them for actual job roles.</p>
                              </div>
                        </div>
                        <div className='col-md-4 '>
                              <div className=' px-md-2 px-0'>
                                    <div className='d-flex'>
                                          <h5 className='rounded-circle mt-1 pt-1 px-2 mb-0 text-white text-center' style={{ backgroundColor: "#107898", height: "35px", width: "35px" }}>2</h5>
                                          <h5 className='ps-3 pt-2' >Real-World Experience</h5>
                                    </div>
                                    <p className='pt-2'>Internship immerse students in professional environments, allowing them to experience day-to-day operations and challenges of their industry. This practical exposure builds confidence, adaptability, and problem-solving abilities crucial for success in their careers.</p>
                              </div>
                        </div>
                        <div className='col-md-4'>
                              <div className='ps-md-4 ps-0'>
                                    <div className='d-flex'>
                                          <h5 className='rounded-circle mt-1 pt-1 px-2 mb-0 text-white text-center' style={{ backgroundColor: "#107898", height: "35px", width: "35px" }}>3</h5>
                                          <h5 className='ps-3 pt-2'>Industry Connections</h5>
                                    </div>
                                    <p className='pt-2'>Internship facilitate networking with industry professionals, mentors, and peers. These connections offer insights into career paths, job opportunities, and industry trends. They also provide valuable references and potential job offers, enhancing students' career prospects.</p>
                              </div>
                        </div>
                  </div>
                  <div className="col-md-6">
                        <div className='row'>
                              <div className="col-md-3">
                                    <button type="button" className="btn btn-sm btn-dark rounded-pill " style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center', backgroundColor: "#1E0A4C" }}>
                                          Apply Now </button></div>
                              <div className="col-md-8 pt-3 pt-md-0">
                                    <button type="button" className="btn btn-sm btn-dark rounded-pill " style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center', backgroundColor: "#1E0A4C" }}>
                                          <a href="/InternshipBrochure.pdf" download className="text-decoration-none  " >
                                                Download Brochure
                                          </a>
                                    </button>
                              </div>
                        </div>
                  </div>
            </div>

      );
};

export default InternshipExperience;
