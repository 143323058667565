import React from 'react';
import '../style/Service.css';
import simg5 from '../assets/Marketsol.png';
const MarketSol = () => {

  return (
    <div className='sbg'>
      <div className='container' >
        <div className='row' style={{ fontSize: "1rem" }}>
          <div className='col-md-5'>
            <div className='row mt-3'>
              <div className='col-md mt-4 pb-4'>
                <p className='text-light d-flex '>PPC Marketing</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md pb-4'>
                <p className='text-light d-flex '>SEO & SMO</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md pb-4'>
                <p className='text-light d-flex '>Digital Business Strategy </p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md pb-4'>
                <p className='text-light d-flex '>SMS & Email Marketing</p>
              </div>
            </div>
            <div className='row mb-0 pb-0'>
              <div className='col-md pb-4'>
                <p className='text-light d-flex '>Conetent Marketing</p>
              </div>
            </div>
          </div>
          <div className='col-md-4 mt-3 d-none d-md-block' style={{ position: "relative" }}>
            <img src={simg5} alt="Image 1" className="img-fluid" style={{ position: 'absolute', bottom: 0 }} />
          </div>
          <div className='col-md-9 mt-3 d-md-none d-block' >
            <img src={simg5} alt="Image 1" className="img-fluid" style={{ bottom: 0 }} />
          </div>
        </div>
      </div>
    </div>


  );
};

export default MarketSol;
