import React, { useState } from 'react';
import '../style/Index1.css';
import img1 from '../assets/Frame421.png';
import img2 from '../assets/Rectangle1.png';
import img3 from '../assets/Rectangle11.png';
import img4 from '../assets/Rectangle2.png';
import img5 from '../assets/Rectangle21.png';
import img6 from '../assets/Rectangle3.png';
import img7 from '../assets/Rectangle31.png';
import ItSolution from './ItSolution';
import WebSolution from './WebSolution';
import AppDev from './AppDev';
import DesignSol from './DesignSol';
import MarketSol from './MarketSol';
import { SlArrowRightCircle, SlArrowLeftCircle, SlArrowDownCircle, SlArrowUpCircle } from "react-icons/sl";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Gallery from './Gallery';
import img8 from '../assets/Rectangle13.png';
import WhatsNew from './WhatsNew';
import OurCustomer from './OurCustomer';
import Slideshow from './Slideshow';
import Navbar from './Navbar';
import Gallery1 from './Gallery1';
AOS.init();
export default function Index1() {
  const [activeComponent, setActiveComponent] = useState("itsolution");

  const handleComponentClick = (componentName) => {
    setActiveComponent(componentName);
  };

  const handleNext = () => {
    switch (activeComponent) {
      case "itsolution":
        setActiveComponent("websolution");
        break;
      case "websolution":
        setActiveComponent("appdev");
        break;
      case "appdev":
        setActiveComponent("designsol");
        break;
      case "designsol":
        setActiveComponent("marketsol");
        break;
      case "marketsol":
        setActiveComponent("itsolution");
        break;
      default:
        setActiveComponent("itsolution");
        break;
    }
  };

  const handlePrevious = () => {
    switch (activeComponent) {
      case "itsolution":
        setActiveComponent("marketsol");
        break;
      case "websolution":
        setActiveComponent("itsolution");
        break;
      case "appdev":
        setActiveComponent("websolution");
        break;
      case "designsol":
        setActiveComponent("appdev");
        break;
      case "marketsol":
        setActiveComponent("designsol");
        break;
      default:
        setActiveComponent("itsolution");
        break;
    }
  };


  return (
    <div className='main  '>
      <div className='gb'>
        <div className='sticky-top topn d-none d-md-block '>
          <Navbar />
        </div>

        <div className='container text-light' >
          <div className='pt-5 d-none d-md-block'>
            <div className='row pt-5'>
              <div className='col-lg-6 mt-2'>
                <h1 className='display-4 fw-bold'>Driving Growth Crafting Solutions.</h1>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-7 text-wrap'>
                <p style={{ fontSize: "22px" }}>Empowering innovation, navigating challenges, and exceeding expectations across all digital landscapes.</p>
              </div>
            </div>
            <div className='row mt-3 pb-4'>
              <div className='col-lg-6'>
                <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center' }}>
                  Get Started
                </button>
              </div>
            </div>
          </div>
          <div className=' d-block d-md-none'>
            <div className='row pt-4'>
              <div className='col-lg-6 mt-2'>
                <h1 className='display-4 fw-bold'>Driving Growth Crafting Solutions.</h1>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-7 text-wrap'>
                <p style={{ fontSize: "22px" }}>Empowering innovation, navigating challenges, and exceeding expectations across all digital landscapes.</p>
              </div>
            </div>
            <div className='row mt-3 pb-4'>
              <div className='col-lg-6'>
                <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center' }}>
                  Get Started
                </button>
              </div>
            </div>
          </div>


        </div>
      </div>
      <div style={{ maxWidth: "1200px" }} className='mx-auto' >
        <div className='container text-center mt-4 mb-5 text-wrap'>
          <h2 style={{ fontWeight: "650" }}> Welcome to Inphynous Technology Solution Pvt. Ltd.</h2>
          <h2 style={{ color: "#107898", fontWeight: "650" }}>Your Trusted IT Partner!</h2>
          <p style={{ fontSize: "18px" }}>At Inphynous Technology Solution Pvt. Ltd., we're your digital transformation partner, driving innovation to propel your business forward.</p>
        </div>
      </div>
      <div className='gb1 pt-5' id="wwd" >
        <div className='container pt-3 text-wrap'>
          <h1 className=' display-6 fw-bold text-center'>What We <span style={{ color: "#107898" }}>Do</span> </h1>
          <div className='row pt-4 pb-3'>
            <div className='col-md-5' data-aos="fade-right">
              <h2 style={{ color: "#1E0A4C", fontWeight: "650" }}>Crafting Tailored Solutions</h2>
              <p className="border-bottom border-5 border-dark pb-3" style={{ color: "#1E1E1E", fontSize: "18px" }}><span style={{ fontSize: "30px", color: "#1E0A4C", fontWeight: "650" }}>W</span>e understand that every business is unique, which is why
                we take a personalized approach to every project we undertake.
                From conceptualization to execution, we work closely with you
                to understand your goals, challenges, and vision, ensuring that
                our solutions are perfectly aligned with your objectives.</p>
            </div>
            <div className='col-md-2'></div>
            <div className='col-md-5' data-aos="fade-left">
              <div className='pt-3 pb-3'>
                <img src={img1} alt="Image" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>
          <div className='row pt-4 pb-3'>
            <div className='col-md-5 order-md-3' data-aos="fade-left">
              <h2 style={{ color: "#1E0A4C", fontWeight: "650" }}>Driving Technological Innovation</h2>
              <p className="border-bottom border-5 border-dark pb-3" style={{ color: "#1E1E1E", fontSize: "18px" }}><span style={{ fontSize: "30px", color: "#1E0A4C", fontWeight: "650" }}>I</span>n a rapidly evolving digital world, staying ahead of the curve is
                crucial. Our team of seasoned experts is constantly exploring
                new technologies and methodologies to deliver cutting-edge
                solutions that drive innovation and keep you ahead of the
                competition.</p>
            </div>
            <div className='col-md-2 order-md-2'></div>
            <div className='col-md-5 order-md-1' data-aos="fade-right">
              <div className='pt-3 pb-3'>
                <img src={img2} alt="Image" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>
          <div className='row pt-4 pb-3'>
            <div className='col-md-5' data-aos="fade-right">
              <h2 style={{ color: "#1E0A4C", fontWeight: "650" }}>Creating Engaging User Experiences</h2>
              <p className="border-bottom border-5 border-dark pb-3" style={{ color: "#1E1E1E", fontSize: "18px" }}><span style={{ fontSize: "30px", color: "#1E0A4C", fontWeight: "650" }}>Y</span>our digital presence is often the first point of contact for potential
                customers, and we're here to make sure it leaves a lasting impression.
                Leveraging the latest design principles and user experience strategies,
                we craft intuitive and visually stunning interfaces that captivate your
                audience and drive engagement.</p>
            </div>
            <div className='col-md-2'></div>
            <div className='col-md-5' data-aos="fade-left">
              <div className='pt-3 pb-3'>
                <img src={img3} alt="Image" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>
          <div className='row pt-4 pb-3'>
            <div className='col-md-5 order-md-3' data-aos="fade-left">
              <h2 style={{ color: "#1E0A4C", fontWeight: "650" }}>Delivering Seamless Integration</h2>
              <p className="border-bottom border-5 border-dark pb-3" style={{ color: "#1E1E1E", fontSize: "18px" }}><span style={{ fontSize: "30px", color: "#1E0A4C", fontWeight: "650" }}>W</span>e understand the importance of seamless integration across
                platforms and systems. Whether it's integrating third-party
                APIs, migrating legacy systems to modern platforms, or ensuring cross-platform compatibility, we're here to streamline your operations and enhance efficiency. </p>
            </div>
            <div className='col-md-2 order-md-2'></div>
            <div className='col-md-5 order-md-1' data-aos="fade-right">
              <div className='pt-3 pb-3'>
                <img src={img4} alt="Image" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>
          <div className='row pt-4 pb-3'>
            <div className='col-md-5' data-aos="fade-right">
              <h2 style={{ color: "#1E0A4C", fontWeight: "650" }}>Ensuring Reliability and Scalability</h2>
              <p className="border-bottom border-5 border-dark pb-3" style={{ color: "#1E1E1E", fontSize: "18px" }}><span style={{ fontSize: "30px", color: "#1E0A4C", fontWeight: "650" }}>O</span>ur solutions are built to withstand the test of time. We prioritize
                reliability and scalability, ensuring that your digital infrastructure can
                adapt and grow alongside your business, no matter how rapidly it
                evolves.</p>
            </div>
            <div className='col-md-2'></div>
            <div className='col-md-5' data-aos="fade-left">
              <div className='pt-3 pb-3'>
                <img src={img5} alt="Image" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>
          <div className='row pt-4 pb-3'>
            <div className='col-md-5 order-md-3' data-aos="fade-left">
              <h2 style={{ color: "#1E0A4C", fontWeight: "650" }}>Driving Results Through
                Data-Driven Insights</h2>
              <p className="border-bottom border-5 border-dark pb-3" style={{ color: "#1E1E1E", fontSize: "18px" }}><span style={{ fontSize: "30px", color: "#1E0A4C", fontWeight: "650" }}>I</span>n today's data-driven world, insights are key to making informed decisions. We leverage advanced analytics and data-driven methodologies to provide actionable insights that empower you to make strategic decisions and drive results.</p>
            </div>
            <div className='col-md-2 order-md-2'></div>
            <div className='col-md-5 order-md-1' data-aos="fade-right">
              <div className='pt-3 pb-3'>
                <img src={img6} alt="Image" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>
          <div className='row pt-4 pb-3'>
            <div className='col-md-5' data-aos="fade-right">
              <h2 style={{ color: "#1E0A4C", fontWeight: "650" }}>Partnering for Success</h2>
              <p className="border-bottom border-5 border-dark pb-3" style={{ color: "#1E1E1E", fontSize: "18px" }}><span style={{ fontSize: "30px", color: "#1E0A4C", fontWeight: "650" }}>Y</span>our success is our success, and we're committed to going above
                and beyond to help you achieve your goals. From initial
                consultation to ongoing support and maintenance, we're with you
                every step of the way, providing unparalleled support and
                expertise.</p>
            </div>
            <div className='col-md-2'></div>
            <div className='col-md-5' data-aos="fade-left">
              <div className='pt-3 pb-3'>
                <img src={img7} alt="Image" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='gb2' id='services'>
        <div className='container'>
          <h1 className='display-6 fw-bold text-center pt-4' style={{ color: "#4CC9F0" }}>Services</h1>
          <div className='row pt-3 ml-0 pl-0'>
            <div className="col-sm ml-0 pl-0">
              <div className='row'>
                <div className="col-6 d-md-none text-end">
                  <button type="button" className="btn btn-sm btn-light rounded-circle pt-2 pb-2" onClick={handlePrevious}>
                    <SlArrowUpCircle size={18} />
                  </button>
                </div>
                <div className="col-6 d-md-none">
                  <button type="button" className="btn btn-sm btn-light rounded-circle pt-2 pb-2" onClick={handleNext}>
                    <SlArrowDownCircle size={18} />
                  </button>
                </div>
              </div>
              <div className="d-flex flex-column flex-sm-row justify-content-evenly flex-wrap ml-0 pl-0 service">
                <a
                  className={activeComponent === "itsolution" ? 'active' : ''}
                  onClick={() => handleComponentClick("itsolution")}
                >
                  IT Solution
                </a>
                <div className='d-md-none'>
                  {activeComponent === "itsolution" && <ItSolution />}
                </div>
                <a
                  className={activeComponent === "websolution" ? 'active' : ''}
                  onClick={() => handleComponentClick("websolution")}
                >
                  Web Solution
                </a>
                <div className='d-md-none'>
                  {activeComponent === "websolution" && <WebSolution />}
                </div>
                <a
                  className={activeComponent === "marketsol" ? 'active' : ''}
                  onClick={() => handleComponentClick("marketsol")}
                >
                  Digital Marketing Solutions
                </a>
                <div className='d-md-none'>
                  {activeComponent === "appdev" && <AppDev />}
                </div>
                <a
                  className={activeComponent === "designsol" ? 'active' : ''}
                  onClick={() => handleComponentClick("designsol")}
                >
                  Designing Solutions
                </a>
                <div className='d-md-none'>
                  {activeComponent === "designsol" && <DesignSol />}
                </div>
                <a
                  className={activeComponent === "appdev" ? 'active' : ''}
                  onClick={() => handleComponentClick("appdev")}
                >
                  App Development
                </a>
                <div className='d-md-none'>
                  {activeComponent === "marketsol" && <MarketSol />}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container mt-3'>
          <div className='row align-items-center'>
            {/* <div className="col-6 d-md-none text-end">
              <button type="button" className="btn btn-sm btn-light rounded-circle pt-2 pb-2" onClick={handlePrevious}>
                <SlArrowUpCircle size={18} />
              </button>
            </div>
            <div className="col-6 d-md-none">
              <button type="button" className="btn btn-sm btn-light rounded-circle pt-2 pb-2" onClick={handleNext}>
                <SlArrowDownCircle size={18} />
              </button>
            </div> */}
            <div className='col-md-1 order-md-1 d-none d-md-block'>
              <button type="button" className="btn-sm btn-light rounded-circle pt-2 pb-2" onClick={handlePrevious}>
                <SlArrowLeftCircle size={28} style={{ paddingLeft: "4px", paddingRight: "4px" }} />
              </button>
            </div>

            <div className='col-md-10 order-md-2 d-none d-md-block'>
              {activeComponent === "itsolution" && <ItSolution />}
              {activeComponent === "websolution" && <WebSolution />}
              {activeComponent === "appdev" && <AppDev />}
              {activeComponent === "designsol" && <DesignSol />}
              {activeComponent === "marketsol" && <MarketSol />}
            </div>

            <div className='col-md-1 order-md-3 d-none d-md-block'>
              <button type="button" className="btn-sm btn-light rounded-circle pt-2 pb-2" onClick={handleNext}>
                <SlArrowRightCircle size={28} style={{ paddingLeft: "4px", paddingRight: "4px" }} />
              </button>
            </div>

          </div>
        </div>


      </div>
      {/* <div class="mb-5 d-none d-md-block" id='portfolio'>
        <Gallery />

      </div> */}

      <div class="container mb-5 d-block d-md-none">
        <div class="row">
          <div class="col">
            <Gallery1 />
          </div>
        </div>
      </div>
      <div class="container mb-5 d-none d-md-block">
        <div class="row">
          <div class="col">
            <Gallery />
          </div>
        </div>
      </div>

      <div className='container'>
        <img src={img8} alt="Image" style={{ width: '100%', height: 'auto' }} />
      </div>
      <div className='container mt-5'>
        <WhatsNew />
      </div>
      
      <div className='container text-center mt-4' id='research'>
        <h1 className='display-6 fw-bold text-center'> Research & <span style={{ color: "#107898" }}>Innovation</span> </h1>
        <h2 className='mb-5' style={{ fontWeight: "650" }}>Innovate Beyond Limits: Pioneering Tomorrow's Solutions Today!</h2>
        <Slideshow />

      </div>
      <div className='gb3'>
        <div className='container text-light'>
          <div className='row'>
            <div className='col-lg-7 mt-2'>
              <h1 className='display-4 fw-bold'>Fuel your digital growth.
                Let’s craft solutions together.</h1>
            </div>
          </div>
          <div className='row mt-3 pb-4'>
            <div className='col-lg-6'>
              <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center' }}>
                Schedule a Meeting
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
