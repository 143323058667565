import React, { useState } from 'react';
import '../style/Services1.css';
import simg1 from '../assets/ITSolution1.png';
import simg2 from '../assets/Web_solution1.png';
import simg3 from '../assets/App_development1.png';
import simg4 from '../assets/Graphic_deisgn1.png';
import simg5 from '../assets/Marketing_solution1.png';
import Navbar from './Navbar';
import { GoArrowUpRight } from 'react-icons/go';
import { Link } from 'react-router-dom';
export default function Resources1() {

  return (
    <div className='main mx-auto'>
      <div className='servicebg' style={{height:"120px"}}>
        <div className='sticky-top topn d-none d-md-block'>      <Navbar />
        </div>
        <div className='container '>
        </div>
      </div>
    </div>
  );
}
