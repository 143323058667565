import React, { useState } from 'react'

const Faq = () => {
     // accoredance data
  const accordionData = [
    {
      title: "What can I expect to learn during an internship?",
      content: "During an internship, you can expect to learn: Practical skills relevant to your field (e.g., marketing strategies, data analysis, content creation).Professional work habits and ethics. How to apply theoretical knowledge in real-world scenarios. Effective communication and teamwork skills."
    },
    {
      title: "Is this internship paid or unpaid?",
      content: "Internship policies vary by company and location. Some internships are paid, offering monetary compensation or stipends, while others may be unpaid but provide valuable experience and learning opportunities. It's essential to clarify compensation details before accepting an internship offer."
    },
    {
      title: "How long do internships typically last?",
      content: "Internship durations vary widely. They can range from a few weeks to several months, depending on the company's internship program and the student's academic schedule. Some internships may also extend to part-time or full-time employment after completion."
    },
    {
      title: "How do I find and apply for internships?",
      content: "You can find internships through various channels: University career centers or job boards. Company websites and social media platforms. Networking events, career fairs, and industry conferences. Professional associations related to your field. To apply, prepare a resume and cover letter tailored to the internship position. Research the company and its culture, and follow application instructions carefully."
    },
    {
      title: "What should I do to make the most of my internship experience?",
      content: "To maximize your internship experience: Take initiative and show eagerness to learn. Ask questions and seek feedback from supervisors and mentors. Network with colleagues and attend company events. Take on additional responsibilities and contribute to projects. Reflect on your experiences and identify areas for personal and professional growth."
    }
  ];
const [openAccordion, setOpenAccordion] = useState(null);
const handleToggle = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };
  return (
    <div className='container '>
    <div className='text-center pb-5'>
    <h1 className='display-6 fw-bold '>FAQs</h1>
    </div>
    <div className='container px-4'>
          <div id="accordion" className='px-md-4'>
      {accordionData.map((item, index) => (
        <div className=" card-faqs mb-3  accordion-body card-body-faq" key={index}>
          <div className="" id={`heading${index}`}>
            <h6
              className="mb-0 accordion-header p-3"
              data-toggle="collapse"
              data-target={`#collapse${index}`}
              aria-expanded={openAccordion === index}
              aria-controls={`collapse${index}`}
              onClick={() => handleToggle(index)}
            >
              {item.title}
              <span
                className="icon"
                style={{
                  padding: '0 5px',
                  marginLeft: '10px'
                }}
              >
                {openAccordion === index ? '-' : '+'}
              </span>
            </h6>
          </div>
          <div
            id={`collapse${index}`}
            className={`collapse ${openAccordion === index ? 'show' : ''}`}
            aria-labelledby={`heading${index}`}
            data-parent="#accordion"
          >
            <div className="card-body-faq accordion-body px-3 py-2">
              {item.content}
            </div>
          </div>
        </div>
      ))}
    </div>
    </div>
    </div>
  )
}

export default Faq