import React, { useState } from 'react';
import Select from 'react-select';
import '../style/ContactUs.css';
import { FaCaretDown, FaRegCheckCircle } from "react-icons/fa";

const DigitalContactUs = () => {
  const [activeInput, setActiveInput] = useState(null);
  const [activeSelect, setActiveSelect] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phoneNo: '',
    comment: ''
  });

  const handleSelectFocus = (id) => {
    setActiveSelect(id);
  };

  const handleSelectBlur = () => {
    setActiveSelect(null);
  };

  const handleInputFocus = (id) => {
    setActiveInput(id);
    const input = document.getElementById(id);
    if (input) {
      input.placeholder = '';
    }
  };

  const handleInputBlur = (id, placeholder) => {
    setActiveInput(null);
    const input = document.getElementById(id);
    if (input) {
      input.placeholder = placeholder;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('http://localhost:5000/digitalcontact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.text())  
      .then((data) => {
        console.log('Success:', data);
        setFormData({
          email: '',
          firstName: '',
          lastName: '',
          phoneNo: '',
          comment: ''
        });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSelectChange = (selectedOption, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: selectedOption ? selectedOption.value : '',
    }));
  };

  const inputFields = [
    { id: 'firstName', label: 'First Name', placeholder: 'First Name', isTextarea: false },
    { id: 'lastName', label: 'Last Name', placeholder: 'Last Name', isTextarea: false },
    { id: 'phoneNo', label: 'Mobile No', placeholder: 'Mobile No', isTextarea: false },
    { id: 'email', label: 'Email', placeholder: 'Email', isTextarea: false },
    { id: 'comment', label: 'Comment', placeholder: 'Comment', isTextarea: true },
  ];



  return (

    <div className='contactbg pb-3'>
      <div className='container'>
        <div className='row align-center'>
          <div className='col-md-5  colc4'>
            <h1 className='pt-5 mt-4' style={{ fontSize: "3.5em", fontWeight: "bold" }}>Take Your Business Digital!</h1>
            <h4 className='pt-3' style={{ color: "#4CC9F0" }}>No online presence means missed opportunities.</h4>
            <div className='row mx-2 mt-4'>
              <div className='col-1'><FaRegCheckCircle style={{ color: "#4CC9F0" }} /></div>
              <div className='col-11'>
                <p>From stunning websites to efficient management systems, we've got you covered.</p>
              </div>
            </div>
            <div className='row mx-2'>
              <div className='col-1'><FaRegCheckCircle style={{ color: "#4CC9F0" }} /></div>
              <div className='col-11'>
                <p>Captivate your audience, drive sales, and streamline operations.</p>
              </div>
            </div>
            <div className='row mx-2'>
              <div className='col-1'><FaRegCheckCircle style={{ color: "#4CC9F0" }} /></div>
              <div className='col-11'>
                <p>Affordable prices, expert solutions, and personalized support await you.</p>
              </div>
            </div>
            <h4 className='pt-3' style={{ color: "#4CC9F0" }}>Ready to make your mark? Fill out this form and let's Chat!</h4>
          </div>
          <div className='col-md-1'></div>
          <div className='col-md-6 colc6 conatct-bg px-5'>
            <div className='row'>
              <div className='col-md-10 mt-2 pt-5'>
                <form className='rounded-4 py-4 px-4' onSubmit={handleSubmit} style={{ backgroundColor: "#fff", color: "#000", fontSize: "12px" }}>
                  <h4 className='pb-2' style={{ borderBottomColor: "#1E0A4C", borderBottomStyle: "solid", color: "#000" }}>Get a Personalized Demo</h4>
                  {inputFields.map((field) => (
                    <div className="form-group mt-3" key={field.id}>
                      {activeInput === field.id && <label className='label-active' htmlFor={field.id}>{field.label}</label>}
                      {activeSelect === field.id && <label className='label-active' htmlFor={field.id}>{field.label}</label>}
                      {field.isTextarea ? (
                        <textarea
                          style={{ resize: "none" }}
                          id={field.id}
                          name={field.id}
                          rows={3}
                          placeholder={field.placeholder}
                          className="form-control rounded-4 border-dark"
                          onFocus={() => handleInputFocus(field.id)}
                          onBlur={() => handleInputBlur(field.id, field.placeholder)}
                          onChange={handleInputChange}
                          value={formData[field.id]}
                        />
                      ) : (
                        <input
                          type="text"
                          id={field.id}
                          name={field.id}
                          placeholder={field.placeholder}
                          className="form-control rounded-5 border-dark"
                          onFocus={() => handleInputFocus(field.id)}
                          onBlur={() => handleInputBlur(field.id, field.placeholder)}
                          onChange={handleInputChange}
                          value={formData[field.id]}
                          required
                        />
                      )}
                    </div>
                  ))}
                  <button type="submit" className="btn rounded-5 my-3 px-4 py-1" style={{ backgroundColor: "#1E0A4C", color: "#fff" }}>Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalContactUs;


  


