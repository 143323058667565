import React from 'react';
import Logo1 from '../assets/ITSWhite021.png';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import '../style/Footer.css'
const Footer = () => {
  return (
    <footer className="footer pt-4 pb-0 mb-0 " style={{ backgroundColor: "#010325", color: "#FFFFFF", fontSize: "12px" }}>
      <div className="container " >
        <div className="row footer1 pb-3">
          <div className="col-lg-5">
            <div className="company-info">
              <img src={Logo1} alt="Company Logo" className="mb-3" />
              <p className='col-md-8'>
                Shop no-110, Elite square complex, Beed bypass rd, Beside kalpak lown, MIDC, Chh Sambhajinagar </p>
              <p className='col-md-3 pb-0 mb-0'>7030304084</p>
              <p className='col-md-3 pt-0 mt-0'> 0240-297-5010</p>

              <p><a className='text-decoration-none text-light footeractive' href="#"> info@inphynous.tech</a></p>
            </div>
          </div>
          <div className="col-lg-3">
            <h5 className='border-bottom pb-2 mt-4 pt-2'>FOLLOW US</h5>
            <ul className="list-inline social-icons mb-4 mt-4">
              <li className="list-inline-item"><a href="#" className='text-light footeractive'><FaFacebook size={32} /></a></li>
              <li className="list-inline-item"><a href="#" className='text-light footeractive'><FaLinkedin size={32} /></a></li>
              <li className="list-inline-item"><a href="#" className='text-light footeractive'><FaTwitter size={32} /></a></li>
              <li className="list-inline-item"><a href="#" className='text-light footeractive'><FaInstagram size={32} /></a></li>
            </ul>
            <button className="btn btnfooter rounded-pill mt-4" >Contact Us</button>
          </div>
          <div className='col-lg-1'></div>
          <div className="col-lg-3">
            <h5 className='border-bottom pb-2 mt-4 pt-2'>QUICK LINKS</h5>
            <div className="row">
              <div className="col-md-6 mt-3">

                <ul className="list-unstyled">
                  <li className='pb-2'><a className='text-decoration-none text-light footerlink' href="#"> Our Clients</a></li>
                  <li className='pb-2'><a className='text-decoration-none text-light footerlink' href="#">Privacy policy </a></li>
                  <li className='pb-2'><a className='text-decoration-none text-light footerlink' href="#">Payment Policy</a></li>
                  <li className='pb-2'><a className='text-decoration-none text-light footerlink' href="#">Tearm Of Service</a></li>
                  <li className='pb-2'><a className='text-decoration-none text-light footerlink' href="#">Schedule a Meeting</a></li>
                </ul>
              </div>

              <div className="col-md-6 mt-3">
                <ul className="list-unstyled ">
                  <li className='pb-2'><a className='text-decoration-none text-light footerlink' href="#">Blog</a></li>
                  <li className='pb-2'><a className='text-decoration-none text-light footerlink' href="#">Support</a></li>
                  <li className='pb-2'><a className='text-decoration-none text-light footerlink' href="#">Careers</a></li>
                  <li className='pb-2'><a className='text-decoration-none text-light footerlink' href="#">Resources</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <p className='text-center mt-3 pb-4 mb-0'> © 2021 Inphynous. All rights reserved. Legal</p>
      </div>
    </footer>
  );
};

export default Footer;
