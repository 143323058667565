import React, { useState } from 'react';
import '../style/Index1.css';
import '../style/Internship.css'
import '../style/DigitalMarketing.css';
import ItSolution from '../assets/ITSolution1.png';
import WebSolution from '../assets/Web_solution1.png';
import AppDev from '../assets/App_development1.png';
import DesignSol from '../assets/Graphic_deisgn1.png';
import MarketSol from '../assets/Marketing_solution1.png';
import { SlArrowRightCircle, SlArrowLeftCircle, SlArrowDownCircle, SlArrowUpCircle } from "react-icons/sl";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Gallery from './Gallery';
import internshipgirl from '../assets/internshipgirl.png';
import WhatsNew from './WhatsNew';


import Navbar from './Navbar';
import Gallery1 from './Gallery1';
import { GoArrowUpRight } from 'react-icons/go';
import { MdChecklistRtl, MdDraw, MdGroups3, MdOutlineCampaign, MdOutlineEmojiObjects, MdOutlineGroups3, MdOutlinePsychology, MdOutlineQueryStats, MdOutlineSchedule, MdSubscriptions } from 'react-icons/md';
import { ImStatsBars } from 'react-icons/im';
import { PiNumberCircleOneFill, PiNumberCircleThreeFill, PiNumberCircleTwoFill } from 'react-icons/pi';
import ContactUs from './ContactUs';
import DigitalContactUs from './DigitalContactUs';
import ProgramHighlight from './ProgramHighlight';
import { TbCodeOff } from 'react-icons/tb';
import { FaLaptopCode } from 'react-icons/fa';
import InternshipBenifits from './InternshipBenifits';
import InternshipExperience from './InternshipExperience';
import Faq from './Faq';
import InternshipContactUs from './InternshipContactUs.js';
import Timeline from './InternshipStructure.js';
import InternshipItLandscape from './InternshipItLandscape.js';
import InternshipSuccess from './InternshipSuccess.js';
import InternshipRole from './InternshipRole.js';

AOS.init();
export default function Internship() {
  const events = [
    { id:1, title: "Intensive Training: ", date: "2024-02-01", description: "Daily sessions on critical digital marketing areas." },
    { id:2, title: "Immediate Application:", date: "2024-01-01", description: "Start working on real-world projects from day one." },
    { id:3, title: "Skill Development:", date: "2024-03-01", description: "Master essential tools and techniques." },
    { id:4, title: "Client Projects:", date: "2024-04-01", description: "Gain hands-on experience by working on actual client projects." },
    { id:5, title: "Team Collaboration:", date: "2024-04-01", description: "Develop teamwork skills and meet real deadlines." },
    { id:6, title: "Ongoing Mentorship:", date: "2024-04-01", description: "Receive continuous support and feedback from industry veterans." }

];



  return (
    <div className='main  '>
      <div className='gb'>
        <div className='sticky-top topn d-none d-md-block '>
          <Navbar />
        </div>

        <div className='container text-light' >
          <div className='pt-4 d-none d-md-block'>
            <div className='row pt-5'>
               
              <div className='col-lg-7 pt-5 pb-4'>
                <h1 className='display-5 fw-bold '>Skip the Costly Courses</h1>
                <h1 className='display-5 fw-bold'>Gain Real Experience Now!</h1>
                <div className='col-lg-10 py-3'>
                <p style={{ fontSize: "22px" }}>Join Our Free 6-Month Digital Marketing Internship for Hands-On Skills and Job-Ready Expertise!</p>
                </div>
                <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center' }}>
                <a href="/InternshipBrochure.pdf" download className="text-decoration-none  " >
                  Download Brochure
                </a>                </button>
              </div>
              <div className='col-lg-5 pt-4'>
                <img src={internshipgirl} className='px-4 img-fluid'/>
              </div>
            </div>
          </div>
          <div className=' d-block d-md-none'>
            <div className='row '>
            <div className='col-lg-6 pt-4' style={{ backgroundColor: "#1E0A4C" }}>
                <img src={internshipgirl} className='img-fluid'/>
              </div>
              <div className='col-lg-6 mt-2'>
              <h1 className='display-4 fw-bold'>Skip the Costly Courses </h1>
              <h1 className='display-4 fw-bold'>Gain Real Experience Now!</h1>              </div>
            </div>
            <div className='row'>
              <div className='col-lg-7 text-wrap'>
              <p style={{ fontSize: "22px" }}>Join Our Free 6-Month Digital Marketing Internship for Hands-On Skills and Job-Ready Expertise!</p>
              </div>
            </div>
            <div className='row mt-3 pb-4'>
              <div className='col-lg-6'>
                <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center' }}>
                <a href="/InternshipBrochure.pdf" download className="text-decoration-none  " >
                  Download Brochure
                </a>                 </button>   
              </div>
            </div>
          </div>
        </div>
      </div>
     <div className='container'>
     <div className='px-lg-4 py-5 bg-white'>
     <h2 className=' text-center' style={{fontWeight:"650"}}>Discover the Difference </h2> 
     <h2 className=' text-center' style={{ color: "#107898", fontWeight:"650" }}> Your Go-To Digital Marketing Internship! </h2>
     <p className='text-center pt-2' style={{fontSize:'18px'}}>
     Experience Real Growth and Career-Ready Skills Through Practical Training, Mentorship, and Hands-On Projects. Say Goodbye to Ordinary Certification Courses and Hello to Extraordinary Opportunities!
     </p>
     <div className='container text-center'>
     <div className='row justify-content-center'>
      <div className='col-md-4   rounded-3' style={{border:"1.5px solid #1E0A4C"}}>
        <div className='row'>
      <div className='col my-3'><p className='fw-semibold mb-1 '>Type</p>
      <p className='mb-0'>Online</p></div>
      <div className='col my-3  border-end border-1 border-dark border-start'><p className='fw-semibold mb-1 '>Certificate</p>
      <p className='mb-0'>Yes</p></div>
      
      <div className='col my-3'><p className='fw-semibold mb-1 '>Certificate</p>
      <p className='mb-0'>Yes</p></div>
      </div>
      
      </div>
     </div>
     </div>
     </div>
     </div>
     <div className='pt-3'>
    <ProgramHighlight/>
     </div>   
     <div className=''>
<div className='topics'>
  <div className='container'>
  <h1 className='display-6 fw-bold text-center text-white py-4'>Key Topics & Skills </h1>
  <div className='px-lg-5 mx-5'>
    <div className='row'>
    <div className='col-md-3 pt-3'>
    <div className='card text-center justify-content-center'>
    <MdOutlineQueryStats size={40} className='mx-auto my-3' style={{color:"#1E0A4C"}}  />
      <h6>SEO Mastery</h6>     
    </div>
    </div>
    <div className='col-md-3 pt-3'>
    <div className='card text-center justify-content-center'>
    <MdOutlinePsychology size={40} className='mx-auto my-3' style={{color:"#1E0A4C"}}  />
      <h6>Prompt Creation</h6>     
    </div>
    </div>
    <div className='col-md-3 pt-3'>
    <div className='card text-center justify-content-center'>
    <MdOutlineCampaign size={40} className='mx-auto my-3' style={{color:"#1E0A4C"}}  />
      <h6>Campaigns & Funnels</h6>
     
    </div>
    </div>
    <div className='col-md-3 pt-3'>
    <div className='card text-center justify-content-center'>
    <MdDraw size={40} className='mx-auto my-3' style={{color:"#1E0A4C"}}  />
      <h6>Creative Design</h6>
     
    </div>
    </div>
    </div>
    </div>
    <div className='px-lg-5 mx-5'>
    <div className='row justify-content-center'>
    <div className='col-md-3 pt-3'>
    <div className='card text-center justify-content-center'>
    <TbCodeOff size={40} className='mx-auto my-3' style={{color:"#1E0A4C"}}  />
      <h6>No-Code Tools</h6>     
    </div>
    </div>
    <div className='col-md-3 pt-3'>
    <div className='card text-center justify-content-center'>
    <MdSubscriptions size={40} className='mx-auto my-3' style={{color:"#1E0A4C"}}  />
      <h6>Social Media Management</h6>    
    </div>
    </div>  
    <div className='col-md-3 py-3'>
    <div className='card text-center justify-content-center'>
    <FaLaptopCode size={40} className='mx-auto my-3' style={{color:"#1E0A4C"}}  />
      <h6>Web Development</h6>    
    </div>
    </div>      
  </div>
  </div>
</div>
</div>
      </div> 
      <div className='container pt-5 pb-3 '>
      <h2 className=' fw-bold text-center'>Why Our Internship is the Superior Choice   </h2>
      <h2 className=' fw-bold text-center' style={{ color: "#107898" }}>Real-World Experience Over Certificates </h2>
      <p className='text-center py-4 px-lg-5' style={{fontSize:'18px'}}>
      In today’s fast-paced digital marketing industry, companies prioritize practical experience over costly certificates. Many courses promise high-paying jobs but fail to deliver the hands-on skills needed in the real world. Why spend time and money on theoretical knowledge when you can start gaining practical experience from day one? Our internship equips you with the real-world skills that employers are actively seeking.
      </p>
        </div> 
        <div className='bg-white py-5'>
        <InternshipBenifits/>  
        </div> 
        <div className='py-5'>
        <InternshipExperience/>  

        </div> 
        <div className='py-5 bg-white'>
        <Timeline events={events} />
        </div>
        <div className='py-5 topics'>
        <InternshipItLandscape/>
        </div>
        <div className='py-5'>
        <InternshipSuccess/>
        </div>
        <div className='py-5 bg-white'>
        <InternshipRole/>
        </div>
        <div className='py-5'>
        <Faq/>  
        </div>
        
        <InternshipContactUs/>
    </div>
  );
}
