import React from 'react';
import '../style/Service.css';
import simg1 from '../assets/Websolution.png';
const WebSolution = () => {

  return (
    <div className='sbg'>
      <div className='container' >
        <div className='row' style={{ fontSize: "1rem" }}>
          <div className='col-md-3 '>
            <div className='row mt-3'>
              <div className='col-md mt-4 pb-4'>
                <p className='text-light d-flex '>Web Design & Development</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md pb-4'>
                <p className='text-light d-flex '>Domain & Vosting</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md pb-4'>
                <p className='text-light d-flex '>VPS & Dedicated Servers</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md pb-4'>
                <p className='text-light d-flex '>Digital Certificates</p>
                <br />
              </div>
            </div>
            <div className='row mb-0 pb-0'>
              <div className='col-md d-none d-md-block pb-4'>
                <p className='text-light d-flex '><span> </span></p>
              </div>
            </div>
          </div>
          <div className='col-md-5 mt-3 d-none d-md-block' style={{ position: "relative" }}>
            <img src={simg1} alt="Image 1" className="img-fluid" />
          </div>
          <div className='col-md-9 mt-3 d-md-none order-md-2 d-block' >
            <img src={simg1} alt="Image 1" className="img-fluid" style={{ width: "30em" }} />
          </div>

        </div>
      </div>
    </div>

  );
};

export default WebSolution;
