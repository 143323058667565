import React, { useState, useRef, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { IoMdReorder, IoMdClose, IoMdArrowDropdown } from "react-icons/io";
import { NavLink } from 'react-router-dom';
import '../style/Navbar.css';
import Logo1 from '../assets/ITSWhite021.png';
import Home from './Home';
import About from './About';
import Resources from './Resources';
import Services from './Services';
import Career from './Career';
import Subsidiaries from './Subsidiaries';
import ContactUs from './ContactUs';

const MyNavbar = () => {
  const [activeLink, setActiveLink] = useState(null);
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);
  const [showContent, setShowContent] = useState(false);
  const [rotateArrow, setRotateArrow] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    var handleScroll = () => {
      var scrollPercentage = window.scrollY;

      var navbar = document.querySelector('.navbard');

      if (navbar) {
        if (window.innerWidth >= 768) {
          if (scrollPercentage >= 2) {
            navbar.classList.add('gradient_background_linear');
          } else {
            navbar.classList.remove('gradient_background_linear');
          }
        }
      }
    };

    if (window.innerWidth >= 768) {
      handleScroll();
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  const handleNavLinkClick = (link) => {
    setActiveLink(link);
    setShowContent(false);
    setRotateArrow(false);
  };

  const handleNavLinkHover = (link) => {
    setActiveLink(link);
    setShowContent(true);
    setRotateArrow(true);
  };

  const handleContentMouseLeave = () => {
    setShowContent(false);
    setActiveLink(null);
    setRotateArrow(false);
  };

  const toggleNavbar = () => {
    setNavbarCollapsed(!navbarCollapsed);
  };
  const closeContentContainer = () => {
    setShowContent(false);
    setActiveLink(null);
    setRotateArrow(false);
  };

  return (
    <div className='sticky-top'>
      <Navbar className="navbard gradient_background" expand="lg" variant="dark" >
        <Container>
          <Navbar.Brand href="/">
            <img src={Logo1} alt="Image" style={{ width: '100%', height: 'auto' }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar}>
            {navbarCollapsed ? <IoMdReorder size={24} color="white" /> : <IoMdClose size={24} color="white" />}
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav" className={!navbarCollapsed ? 'show' : ''}>
            <Nav className="ms-auto mt-1">
              <NavLink
                className={`Navlink ${activeLink === 'home' ? 'active' : ''}`}
                to="/"
                onClick={() => handleNavLinkClick('home')}
                onMouseEnter={() => handleNavLinkHover('home')}
              >
                Home<IoMdArrowDropdown size={24} color="white" style={{ transform: rotateArrow && activeLink === 'home' ? 'rotate(180deg)' : 'rotate(0deg)' }} />
              </NavLink>
              <NavLink
                className={`Navlink ${activeLink === 'about' ? 'active' : ''}`}
                to="/aboutus"
                onClick={() => handleNavLinkClick('about')}
                onMouseEnter={() => handleNavLinkHover('about')}
              >
                About<IoMdArrowDropdown size={24} color="white" style={{ transform: rotateArrow && activeLink === 'about' ? 'rotate(180deg)' : 'rotate(0deg)' }} />
              </NavLink>
              <NavLink
                className={`Navlink ${activeLink === 'services' ? 'active' : ''}`}
                to="/services1" //
                onClick={() => handleNavLinkClick('services')}
                onMouseEnter={() => handleNavLinkHover('services')}
              >
                Services<IoMdArrowDropdown size={24} color="white" style={{ transform: rotateArrow && activeLink === 'services' ? 'rotate(180deg)' : 'rotate(0deg)' }} />
              </NavLink>
              <NavLink
                className={`Navlink ${activeLink === 'career' ? 'active' : ''}`}
                to="#career1" 
                onClick={() => handleNavLinkClick('career')}
                onMouseEnter={() => handleNavLinkHover('career')}
              >
                Career<IoMdArrowDropdown size={24} color="white" style={{ transform: rotateArrow && activeLink === 'career' ? 'rotate(180deg)' : 'rotate(0deg)' }} />
              </NavLink>
              <NavLink
                className={`Navlink ${activeLink === 'subsidiaries' ? 'active' : ''}`}
                to="#subsidiaries1"
                onClick={() => handleNavLinkClick('subsidiaries')}
                onMouseEnter={() => handleNavLinkHover('subsidiaries')}
              >
                Subsidiaries<IoMdArrowDropdown size={24} color="white" style={{ transform: rotateArrow && activeLink === 'subsidiaries' ? 'rotate(180deg)' : 'rotate(0deg)' }} />
              </NavLink>
              <NavLink
                className={`Navlink ${activeLink === 'resources' ? 'active' : ''}`}
                to="#resources1" 
                onClick={() => handleNavLinkClick('resources')}
                onMouseEnter={() => handleNavLinkHover('resources')}
              >
                Resources<IoMdArrowDropdown size={24} color="white" style={{ transform: rotateArrow && activeLink === 'resources' ? 'rotate(180deg)' : 'rotate(0deg)' }} />
              </NavLink>
            </Nav>
            <Nav>
              <button type="button" className="btn btn-sm btn-light rounded-pill pb-3" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center' }}>
                <NavLink to="/contactus" style={{ fontSize: 'large', color: '#000', margin: 'auto', textDecoration: 'none' }}>Contact Us</NavLink>
              </button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div ref={contentRef} className={`content-container ${showContent ? '' : 'hidden'}`} onMouseLeave={handleContentMouseLeave}>
        {activeLink === 'home' && <Home closeContentContainer={closeContentContainer} />}
        {activeLink === 'about' && <About closeContentContainer={closeContentContainer} />}
        {activeLink === 'services' && <Services closeContentContainer={closeContentContainer} />}
        {activeLink === 'career' && <Career closeContentContainer={closeContentContainer} />}
        {activeLink === 'subsidiaries' && <Subsidiaries closeContentContainer={closeContentContainer} />}
        {activeLink === 'resources' && <Resources closeContentContainer={closeContentContainer} />}
      </div>
    </div>
  );
};

export default MyNavbar;
