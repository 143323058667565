import React from 'react';
import '../style/Service.css';
import simg3 from '../assets/Appdevelopment.png';
const AppDev = () => {

  return (
    <div className='sbg'>
      <div className='container' >
        <div className='row' style={{ fontSize: "1rem" }}>
          <div className='col-md-5'>
            <div className='row mt-3'>
              <div className='col-md mt-4 pb-4'>
                <p className='text-light d-flex '>Server Support - Firebase/AWS</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md pb-4'>
                <p className='text-light d-flex '>Cloud Computing-AWS</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md pb-4'>
                <p className='text-light d-flex '>Cross-Platform Development</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md pb-4'>
                <p className='text-light d-flex '>Ad Sence & Data Analytics</p>
                <br />
              </div>
            </div>
            <div className='row mb-0 pb-0'>
              <div className='col-md d-none d-md-block pb-4'>
                <p className='text-light d-flex '><span> </span></p>
              </div>
            </div>
          </div>
          <div className='col-md-5 mt-3 d-none d-md-block' style={{ position: "relative" }}>
            <img src={simg3} alt="Image 1" className="img-fluid" style={{ position: 'absolute', bottom: 0 }} />
          </div>
          <div className='col-md-5 mt-3 d-md-none d-block' >
            <img src={simg3} alt="Image 1" className='img-fluid' style={{ bottom: 0 }} />
          </div>
        </div>
      </div>
    </div>

  );
};

export default AppDev;
