
import React from 'react';
import '../style/Home.css';
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { HashLink } from 'react-router-hash-link';

const Services = (props) => {
  const scrollToElement = (el) => {
    const yOffset = document.querySelector('.navbard').offsetHeight + 0;
    const y = el.getBoundingClientRect().top + window.pageYOffset - yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
    closeContnet();
  }

  const scrollToElement1 = (el) => {
    const yOffset = document.querySelector('.navbar').offsetHeight + 0;
    const y = el.getBoundingClientRect().top + window.pageYOffset - yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
    closeNavbarAndContainer();
  };


  const closeNavbarAndContainer = () => {
    props.closeNavbar();
    props.closeContentContainer();
  };
  const closeContnet = () => {
    props.closeContentContainer();

  }


  return (
    <div className='bg'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-3 mt-4 d-none d-md-block'>
            <HashLink to="/services1#sitsol"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement(el)}
            >
              IT Solutions <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
          <div className='col-md-3 mt-4 d-block d-md-none'>
            <HashLink to="/services1#sitsol"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement1(el)}
              onClick={closeNavbarAndContainer}

            >
              IT Solutions <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 mt-3 d-none d-md-block'>
            <HashLink to="/services1#swebsol"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement(el)}
            >
              Web Solutions <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
          <div className='col-md-3 mt-3 d-block d-md-none'>
            <HashLink to="/services1#swebsol"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement1(el)}
              onClick={closeNavbarAndContainer}

            >
              Web Solutions  <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 mt-3 d-none d-md-block'>
            <HashLink to="/services1#smarketsol"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement(el)}
            >
              Digital Marketing Solutions <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
          <div className='col-md-3 mt-3 d-block d-md-none'>
            <HashLink to="/services1#smarketsol"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement1(el)}
              onClick={closeNavbarAndContainer}

            >
             Digital Marketing Solutions <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 mt-3 d-none d-md-block'>
            <HashLink to="/services1#sdesignsol"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement(el)}
            >
              Graphic Designing <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
          <div className='col-md-3 mt-3 d-block d-md-none'>
            <HashLink to="/services1#sdesignsol"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement1(el)}
              onClick={closeNavbarAndContainer}

            >
              Graphic Designing <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 mt-3 d-none d-md-block'>
            <HashLink to="/services1#sappdev"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement(el)}
            >
            App Development <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
          <div className='col-md-3 mt-3 d-block d-md-none mb-3'>
            <HashLink to="/services1#sappdev"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement1(el)}
              onClick={closeNavbarAndContainer}

            >
             App Development <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
