import React from 'react';
import { Carousel } from 'react-bootstrap';
import imgs1 from '../assets/Property1.png';
import imgs2 from '../assets/Property2.png';
import imgs3 from '../assets/Property3.png';
import imgs4 from '../assets/Property4.png';
import imgs5 from '../assets/Property5.png';
import imgs11 from '../assets/Propertym1.png';
import imgs21 from '../assets/Propertym2.png';
import imgs31 from '../assets/Propertym3.png';
import imgs41 from '../assets/Propertym4.png';
import imgs51 from '../assets/Propertym5.png';
import '../style/Slideshow.css';

const Slideshow = () => {
  return (
    <Carousel interval={1000} controls={false} className='carouselslide'>
      <Carousel.Item>
        <img
          className="d-md-block w-100 d-none"
          src={imgs1}
          alt="First slide"
        />
        <img
          className="d-md-block d-md-none"
          src={imgs11}
          alt="First slide"
        />
        <Carousel.Caption className="carousel-caption-wrap">
          <p className='pt-5'>Discover our Research and Innovations hub at Inphynous Technology Solution Pvt. Ltd,where creativity meets cutting-edge technology. In a world defined by rapid change and relentless evolution, we thrive on the forefront of innovation,continuously exploring newfrontiers to revolutionize the digital landscape.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-md-block w-100 d-none"
          src={imgs2}
          alt="Second slide"
        />
        <img
          className="d-md-block d-md-none text-wrap"
          src={imgs21}
          alt="Second slide"
        />
        <Carousel.Caption className="carousel-caption-wrap">
          <p className='pt-5'>Here, we don't just follow trends – we set them. Our team of visionary thinkers and forward-looking creators are driven by a shared passion for pushing boundaries and challenging the status quo. With our unique blend of imagination and technical prowess, we craft solutions that not only meet today's demands but also anticipate tomorrow's needs.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-md-block w-100 d-none"
          src={imgs3}
          alt="Third slide"
        />
        <img
          className="d-md-block d-md-none"
          src={imgs31}
          alt="Third slide"
        />
        <Carousel.Caption className="carousel-caption-wrap">
          <p className='pt-5'>Our commitment to innovation extends beyond our own projects; it's ingrained in everything we do. From pioneering products to redefining industry standards, we're constantly pushing the limits of what's possible to deliver unparalleled value to our clients and partners.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-md-block w-100 d-none"
          src={imgs4}
          alt="Fourth slide"
        />
        <img
          className="d-md-block d-md-none"
          src={imgs41}
          alt="Fourth slide"
        />
        <Carousel.Caption className="carousel-caption-wrap">
          <p className='pt-5'>But innovation isn't just about groundbreaking ideas; it's about practicality and relevance. That's why we approach each project with a fresh perspective, combining our creative expertise with deep industry insights to develop tailored solutions that address our clients' unique challenges and aspirations.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-md-block w-100 d-none"
          src={imgs5}
          alt="Fifth slide"
        />
        <img
          className="d-md-block d-md-none"
          src={imgs51}
          alt="Fifth slide"
        />
        <Carousel.Caption className="carousel-caption-wrap">
          <p className=' pt-5 pb-0 mb-0'>At Inphynous, innovation isn't just a buzzword – it's our guiding principle.</p>
          <p className='pt-0 mt-0'>Join us on a journey of discovery and transformation as we pave the way for a brighter, more innovative future together.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Slideshow;
