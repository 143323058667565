import React, { useState } from 'react';
import '../style/Index1.css';
import '../style/DigitalMarketing.css';
import ItSolution from '../assets/ITSolution1.png';
import WebSolution from '../assets/Web_solution1.png';
import AppDev from '../assets/App_development1.png';
import DesignSol from '../assets/Graphic_deisgn1.png';
import MarketSol from '../assets/Marketing_solution1.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from './Navbar';
import { GoArrowUpRight } from 'react-icons/go';
import { MdChecklistRtl, MdGroups3, MdOutlineEmojiObjects, MdOutlineGroups3, MdOutlineSchedule } from 'react-icons/md';
import { ImStatsBars } from 'react-icons/im';
import { PiNumberCircleOneFill, PiNumberCircleThreeFill, PiNumberCircleTwoFill } from 'react-icons/pi';
import DigitalContactUs from './DigitalContactUs';

AOS.init();
export default function DigitalMarketing() {
  return (
    <div className='main  '>
      <div className='gb'>
        <div className='sticky-top topn d-none d-md-block '>
          <Navbar />
        </div>

        <div className='container text-light' >
          <div className='pt-5 d-none d-md-block'>
            <div className='row pt-5'>
              <div className='col-lg-11 mt-2'>
                <h1 className='display-4 fw-bold'>Unlock Your Business Potential with Our Expert Digital Marketing Services</h1>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-7 text-wrap'>
                <p style={{ fontSize: "22px" }}>Transform Your Online Presence and Drive More Traffic, Leads, and Sales!</p>
              </div>
            </div>
            <div className='row mt-3 pb-4'>
              <div className='col-lg-6'>
                <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center' }}>
                Book Our Service
                </button>
              </div>
            </div>
          </div>
          <div className=' d-block d-md-none'>
            <div className='row pt-4'>
              <div className='col-lg-6 mt-2'>
                <h1 className='display-4 fw-bold'>Unlock Your Business Potential with Our Expert Digital Marketing Services</h1>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-7 text-wrap'>
                <p style={{ fontSize: "22px" }}>Transform Your Online Presence and Drive More Traffic, Leads, and Sales!</p>
              </div>
            </div>
            <div className='row mt-3 pb-4'>
              <div className='col-lg-6'>
                <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center' }}>
                Book Our Service
                </button>   
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ maxWidth: "1200px" }} className='mx-auto' >
        <div className='container text-center mt-4 mb-5 text-wrap'>
          <h2 style={{ fontWeight: "650" }}>Welcome to Inphynous Technology Solution Pvt. Ltd.</h2>
          <h2 style={{ color: "#107898", fontWeight: "650" }}>
 Your Go-To Digital Marketing Experts!</h2>
          <h6 style={{ fontSize: "18px" }}>
Where we specialize in delivering comprehensive digital marketing solutions tailored to your business needs. Our team of experts is dedicated to helping you achieve measurable results and outperform your competition.</h6>
        </div>
      </div>
      <div className='bg-black pt-5' id="eys" >
        <div className='container text-wrap'>
          <h1 className=' display-6 fw-bold text-center text-white'>Elevate Your <span style={{ color: "#107898" }}> Sells</span> </h1>
          <p className='text-white pt-4' style={{font:'22px'}}>Success in business depends on maintaining consistent and high-volume sales. To boost sales, it is essential to engage people with effective marketing strategies. In the modern digital era, creating a robust online presence and utilizing digital marketing are key for reaching out to potential customers and presenting your products or services.</p>
         <div className='d-flex justify-content-center py-4 '> <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center', backgroundColor:"#1E0A4C" }}>
                Book Our Service </button></div>
        </div>
      </div>
      <div className='container bg-white pt-5'>
        <h1 className=' display-6 fw-bold text-center pt-2'>Our Digital Marketing <span style={{ color: "#107898" }}> Services</span> </h1>
        <div  className='row justify-content-center mt-5 py-md-3 px-3 px-md-0'>
          <div className='col-md-3 p-3 mx-3 mb-3 card-shadow rounded'>
            <div className='d-flex justify-content-between'>
          <h5 className='pe-3 col-6' style={{ color: "#107898" }}>SEO Optimization</h5>
          <GoArrowUpRight size={26} color='#107898'/>
          </div>
          <p>
          Increase your visibility on search engines and attract more organic traffic with our proven SEO strategies
          </p>
          <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center', backgroundColor:"#1E0A4C" }}>
               Learn More </button>
          </div>
          <div className='col-md-3 p-3 mb-3 mx-3 card-shadow rounded'>
          <div className='d-flex justify-content-between'>
          <h5 className='pe-3 col-6' style={{ color: "#107898" }}>Content Marketing</h5>
          <GoArrowUpRight size={26} color='#107898'/>
          </div>
          <p>
          Create compelling content that resonates with your audience and drives conversions. <span className='text-white'>drives conversions.</span>
          </p>
          
          <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center', backgroundColor:"#1E0A4C" }}>
               Learn More </button>
          </div>
          <div className='col-md-3 p-3 mb-3 mx-3 card-shadow rounded'>
          <div className='d-flex justify-content-between'>
          <h5 className='pe-3 col-8' style={{ color: "#107898" }}>Social Media Management</h5>
          <GoArrowUpRight size={26} color='#107898'/>
          </div>
          <p>
          Engage your audience and build a strong brand presence across all major social media platforms.
          </p>
          <button type="button" className="btn btn-sm btn-dark rounded-pill " style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center', backgroundColor:"#1E0A4C" }}>
               Learn More </button>
          </div>
        </div>
        <div  className='row justify-content-center px-3 px-md-0 py-md-3'>
          
          <div className='col-md-3 p-3 mb-3 mx-3 card-shadow rounded'>
          <div className='d-flex justify-content-between'>
          <h5 className='pe-3 col-6' style={{ color: "#107898" }}>PPC Advertising</h5>
          <GoArrowUpRight size={26} color='#107898'/>
          </div>
          <p>
          Maximize your ROI with targeted pay-per-click campaigns that reach the right audience at the right time.           </p>
          <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center', backgroundColor:"#1E0A4C" }}>
               Learn More </button>
          </div>
          <div className='col-md-3 p-3 mb-3 mx-3 card-shadow rounded'>
          <div className='d-flex justify-content-between'>
          <h5 className='pe-3 col-6' style={{ color: "#107898" }}>Email 
Marketing</h5>
          <GoArrowUpRight size={26} color='#107898'/>
          </div>
          <p>
          Nurture leads and boost customer loyalty with personalized email marketing campaigns.          </p>
          <button type="button" className="btn btn-sm btn-dark rounded-pill " style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center', backgroundColor:"#1E0A4C" }}>
               Learn More </button>
          </div>
        </div>
        </div>
        <div className='gb1 mt-5'>
        <div className='container'>
        <div className='row py-5 my-3'>
          <div className='col-md-5 pb-3 pe-md-5'>
          <h1 className=' display-6 fw-bold py-2'>Facts That Every <span style={{color:"#107898"}}> Business Should Follow</span></h1>
          <p className='pe-md-5 pt-3 pb-4' style={{fontSize:"22px"}}>Know how leading business use digital platforms to grow more !
          </p>
         
          </div>
          <div className='col-md-7 d-md-block d-none '>
            <div className='position-absolute '>
            <div className='digital-card1 rounded-3 p-3 '>
              <p className='py-3 mb-0'>
              More than 1.2 million businesses use Google Ads to advertise their products and services.
              </p>
            </div>
            <div className='digital-card2 rounded-3 p-3'>
              <p className='mb-0'>In 2023, 29% of respondents ages 18 to 24 said they paid the most attention to social media advertising, followed by television and the internet</p>
            </div>
            <div className='digital-card3 rounded-3 p-3'>
              <p className='mb-0'>Google, Spotify, Microsoft, LinkedIn, Meta, X (formerly Twitter) and Pinterest are the leading digital platforms advertisers use</p>
            </div>
            </div>
          </div>
          <div className='col-md-7 d-md-none d-block '>
            <div className='mt-3'>
            <div className='digital-card-md mt-2 rounded-3 p-3 '>
              <p className='py-3 mb-0'>
              More than 1.2 million businesses use Google Ads to advertise their products and services.
              </p>
            </div>
            <div className='digital-card-md mt-2 rounded-3 p-3'>
              <p className='mb-0'>In 2023, 29% of respondents ages 18 to 24 said they paid the most attention to social media advertising, followed by television and the internet</p>
            </div>
            <div className='digital-card-md mt-2 mb-4 rounded-3 p-3'>
              <p className='mb-0'>Google, Spotify, Microsoft, LinkedIn, Meta, X (formerly Twitter) and Pinterest are the leading digital platforms advertisers use</p>
            </div>
            </div>
          </div>
          <div className=''>
          <button type="button" className="btn btn-sm btn-dark rounded-pill " style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center', backgroundColor:"#1E0A4C" }}>
               Request a call </button>
          </div>
        </div>
        </div>
        </div>
    <div className='container'>
    <h1 className=' display-6 fw-bold text-center pt-5'>What you will <span style={{ color: "#107898" }}>  get from us ?</span> </h1>
    <div  className='row justify-content-center mt-md-4 py-md-3 mb-0 px-3 px-md-0'>
          
          <div className='col-md-3 py-4 mt-3 px-2 mx-3 digital-col rounded-5'>
            <div className='d-flex px-2'>
            <MdChecklistRtl  size={26} color='#107898'/>
            <h5 className='ps-3' >Tailored Strategies</h5>
            </div>
            <p className='pt-3 mb-0 text-center'>
            Customized digital marketing plans specific to your business and industry.            </p>
            
            </div>
            <div className='col-md-3 mt-3 py-4 px-2 mx-3 digital-col rounded-5'>
            <div className='d-flex px-2'>
            <MdOutlineGroups3 size={26} color='#107898'/>
            <h5 className='ps-3' >Expert Team</h5>
            </div>
            <p className='pt-3 mb-0 text-center'>
            A dedicated team of professionals with years of experience in digital marketing.            </p>
            </div>
            <div className='col-md-3 mt-3 py-4 px-2  mx-3 digital-col rounded-5'>
            <div className='d-flex px-2'>
            <MdOutlineSchedule size={26} color='#107898'/>
            <h5 className='ps-3' >24/7 Support </h5>
            </div>
            <p className='pt-3 mb-0 text-center'>
            Round-the-clock assistance to ensure your campaigns are always optimized..            
            </p>
            </div>
          </div>
        <div  className='row justify-content-center mt-md-4 px-3 px-md-0 pb-0'>
          
        <div className='col-md-3 py-4 mt-3 px-2 mx-3 digital-col rounded-5'>
          <div className='d-flex px-2'>
          <ImStatsBars size={26} color='#107898'/>
          <h5 className='ps-3' >Advanced Analytics</h5>
          </div>
          <p className='pt-3 mb-0 text-center'>
          In-depth reporting and analytics to track your progress and refine strategies.
          </p>
          
          </div>
          <div className='col-md-3 mt-3 py-4 px-2  mx-3 digital-col rounded-5'>
          <div className='d-flex px-2'>
          <MdOutlineEmojiObjects size={26} color='#107898' className=''/>
          <h5 className='ps-3' >Innovative Solutions </h5>
          </div>
          <p className='pt-3 mb-0 text-center'>
          Cutting-edge tools and techniques to keep your business ahead of the curve.           </p>
          
          </div>
        </div>
    </div>
     <div className='container pt-5'>
      <div className='col-md-6'>
        <h4 style={{ color: "#107898" }}>How we work </h4>
        <h1 className=' display-6 fw-bold'>We’re Here To <span style={{ color: "#107898" }}> Help Your Business Grow</span> </h1>
        <p className='col-lg-9' style={{ fontSize: "18px" }}>Get a way to grow your business with us in just 3 easy steps to boost your sells and get leads. </p>
      </div>
      <div className='row py-3 mt-3'>
      <div className='col-md-4'>
<div className='pe-md-4'>
<div className='d-flex'>
<h5 className='rounded-circle mt-1 pt-1 px-2 mb-0 text-white text-center' style={{backgroundColor:"#107898", height:"35px", width:"35px"}}>1</h5>
<h5 className='ps-3 pt-2' >Book a Free Consultation Call </h5>
</div>
<p className='pt-2'>Start your journey by scheduling a free consultation call to discuss your business goals, challenges, and unique needs.</p>
      </div>
      </div>
      <div className='col-md-4 '>
<div className=' px-md-2'>
<div className='d-flex'>
<h5 className='rounded-circle mt-1 pt-1 px-2 mb-0 text-white text-center' style={{backgroundColor:"#107898", height:"35px", width:"35px"}}>2</h5>
<h5 className='ps-3 pt-2' >Comprehensive Business Analysis</h5>
</div>
<p className='pt-2'>After consulting, experts analyze your business, industry, and competitors to identify opportunities and customize strategies for optimal results.</p>
      </div>   
</div>
      <div className='col-md-4'>
<div className='ps-md-4'>
<div className='d-flex'>
<h5 className='rounded-circle mt-1 pt-1 px-2 mb-0 text-white text-center' style={{backgroundColor:"#107898", height:"35px", width:"35px"}}>3</h5>
<h5 className='ps-3 pt-2'>Detailed Strategy Presentation</h5>
</div>
<p className='pt-2'>Upon completion of our analysis, we will provide a detailed digital marketing strategy with actionable steps, timelines, and expected outcomes.</p>
      </div>
      </div>
      </div>
      <div className='col-md'>
      <button type="button" className="btn btn-sm btn-dark rounded-pill " style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center', backgroundColor:"#1E0A4C" }}>
               Book our Service </button>
      </div>
     </div>
      <div className='container mt-4'>
      <h1 className=' display-6 fw-bold text-center pt-4'> Numbers <span style={{ color: "#107898" }}> Speak Louder</span> </h1>
      <h5  className='text-center mt-4 pb-5'>Our Success in Figures</h5>
        <div className='row px-2 px-md-1 '>
          <div className='col-md me-md-4 mb-2 py-4 digital-numbers '>
          <h5 className='text-center mx-5'> 250+ Businesses</h5>
          <p className='text-center px-2 mb-0 pt-3'>Assisted 250+ businesses in building their digital presence and achieving their online goals.</p>
          </div>
          <div className='col-md mx-md-4 mb-2 py-4 digital-numbers '>
          <h5 className='text-center mx-5'> 250+ Businesses</h5>
          <p className='text-center px-2 mb-0 pt-3'>Optimized 150+ websites to improve user experience and search engine rankings, achieving an average traffic increase of 50% </p>
          </div>
          <div className='col-md mx-md-4 mb-2 py-4 digital-numbers '>
          <h5 className='text-center mx-5'> 250+ Businesses</h5>
          <p className='text-center px-2 mb-0 pt-3'>Helped over 300 companies enhance their social media engagement, resulting in a combined 10 million followers across platforms.</p>
          </div>
          <div className='col-md ms-md-4 mb-2 py-4 digital-numbers '>
          <h5 className='text-center mx-5'> 250+ Businesses</h5>
          <p className='text-center px-2 mb-0 pt-3'>Produced 1,000+ pieces of high-quality content, including blog posts, infographics, and videos, driving significant traffic and customer engagement.</p>
          </div>
        </div>
      </div>
      <div className='container mt-4'>
      <h1 className=' display-6 fw-bold text-center pt-4'> Our <span style={{ color: "#107898" }}> Other Services</span> </h1>
      <div  className='row justify-content-center mt-5 py-md-3 px-3 px-md-0'>
          <div className='col-md-3 p-3 pb-5 mx-3 mb-3 card-shadow rounded text-center position-relative'>
            <div className=' '>
              <img src={ItSolution} className='img-fluid py-3' style={{height:'180px'}}/>
              <h5 className='pb-3'>IT Solutions</h5>

          </div>
          <p>
          "Transforming businesses with our expertise in mobile app development, cloud computing, chatbot development, data analytics & business intelligence, and artificial intelligence services, we offer innovative solutions tailored to your unique needs."          
          </p>
         <div className='d-flex justify-content-center  position-absolute other-services'>
          <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center', backgroundColor:"#1E0A4C" }}>
               Learn More </button>
          </div>
          </div>
          <div className='col-md-3 p-3 pb-5 mb-3 mx-3 card-shadow rounded text-center position-relative'>
          <div className=' '>
            <div className='px-5'>
              <img src={AppDev} className='img-fluid py-3' style={{height:'180px'}}/>
              </div>
              <h5 className='pb-3'>App Development</h5>

          </div>
          <p>
          "We Have Expertise In Creating Multi-Platform Mobile App Solutions For Both Android And IOS Devices. Using PhoneGap, Xamarin, And React Native, We Offer Custom Mobile App That Runs Smoothly On Multiple Platforms.
          </p>
         
         <div className='d-flex justify-content-center position-absolute other-services'>
          <button type="button" className=" btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center', backgroundColor:"#1E0A4C" }}>
               Learn More </button>
          </div>
          </div>         
          <div className='col-md-3 p-3 pb-5 mb-3 mx-3 card-shadow rounded text-center position-relative'>
          <div className=' '>
            <div className='px-5'>
              <img src={WebSolution} className='img-fluid py-3' style={{height:'180px'}}/>
              </div>
              <h5 className='pb-3'>Web Solutions</h5>

          </div>
          <p>
          "Our Custom Web Development Services Include Both Front-End And Back-End Development. Whether It Is Enhancing An Existing App Or Architecting An Enterprise App, Our Developers Are Up For The Challenge.          </p>
         
         <div className='d-flex justify-content-center position-absolute other-services'>
          <button type="button" className=" btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center', backgroundColor:"#1E0A4C" }}>
               Learn More </button>
          </div>
          </div>
        </div>
        <div  className='row justify-content-center px-3 px-md-0 py-md-3'>
          
        <div className='col-md-3 p-3 pb-5 mb-3 mx-3 card-shadow rounded text-center position-relative'>
          <div className=' '>
            <div className='px-5'>
              <img src={DesignSol} className='img-fluid py-3' style={{height:'180px'}}/>
              </div>
              <h5 className='pb-3'>Designing Solutions</h5>

          </div>
          <p>
          "We Understand The Pivotal Role In Making A Lasting Impression On Your Audience. Our Expert Are Here To Elevate Your Brand's Online Presence With Stunning Visuals That Are Captivate And Resonate. Whether You're Looking To Refresh Your Existing Website Or Create A Brand New One.          </p>
         
         <div className='d-flex justify-content-center position-absolute other-services'>
          <button type="button" className=" btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center', backgroundColor:"#1E0A4C" }}>
               Learn More </button>
          </div>
          </div> 
          <div className='col-md-3 p-3 pb-5 mb-3 mx-3 card-shadow rounded text-center position-relative'>
          <div className=' '>
            <div className='px-5'>
              <img src={MarketSol} className='img-fluid py-3' style={{height:'180px'}}/>
              </div>
              <h5 className='pb-3'>Marketing Solutions</h5>

          </div>
          <p>
          "The Digital Marketing Services That We Provide Have Their Own Set Of Charms. By Taking Our Digital Marketing Services, Our Clients Will Be Able To Increase Visibility And Engage With Their Customers At The Online Platform.           </p>
         
         <div className='d-flex justify-content-center position-absolute other-services'>
          <button type="button" className=" btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center', backgroundColor:"#1E0A4C" }}>
               Learn More </button>
          </div>
          </div> 
        </div>
      </div>
      <DigitalContactUs/>
    </div>
  );
}
