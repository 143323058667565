import React from 'react';
import '../style/WhatsNew.css';
const WhatsNew = () => {
  return (

    <div className='container' style={{ maxWidth: "1200px" }} >
      <h1 style={{ fontWeight: "650" }} className='text-center'>What's <span style={{ color: "#107898" }}>New</span> </h1>

      <div className='WNBackground d-flex align-items-end flex-column bd-highlight mb-3 mt-4'>
        <div class="mt-auto p-4 product text-wrap">
          <h4 style={{ fontWeight: "600", color: "#107898" }}> Product Spotlight</h4>
          <h2 style={{ fontWeight: "650" }}>Inphynous Launches Digital Asset
            Classification</h2>
          <p>
            We have launched new product capabilities to expand the Seclore
            data-centric security platform. Read the blog to learn more about
            Seclore Digital Asset Classification.
          </p>

        </div >
      </div>
      <div className='container'>
        <div className='row overflow-hidden'>
          <div className='col-md coln pt-3 text-wrap'>
            <h6 style={{ fontWeight: "600", color: "#107898" }}>RFP Evaluation</h6>
            <h5 className='pb-2'>Data Centric Security
              Evaluation Checklist</h5>

          </div>
          <div className='col-md coln pt-3 text-wrap'>
            <h6 style={{ fontWeight: "600", color: "#107898" }}>White Paper</h6>
            <h5 className='pb-2'>Closing the Gap in Data
              Security</h5>
          </div>
          <div className='col-md coln pt-3 text-wrap'>
            <h6 style={{ fontWeight: "600", color: "#107898" }}>eBook</h6>
            <h5 className='pb-2'>Why Data is the New
              Edge </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsNew;
