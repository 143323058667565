import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import '../style/Gallery.css';
import image1 from '../assets/Gvr.png';
import image2 from '../assets/Mess.png';
import image3 from '../assets/Pinaclecode.png';
import image12 from '../assets/Pinnaclecode1.png';
import image21 from '../assets/GVRLogo.png';
import image31 from '../assets/Swaadmess.png';

const PortfolioCarousel = () => {
    const [selectedCard, setSelectedCard] = useState(null);
    const [isMobileView, setIsMobileView] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth >= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const cards = [
        { id: 1, width: '60%', zIndex: 1, heading: "GVR Trading Academy", content: "Lorem ipsum Lorem ipsumLorem ipsum Lorem Lorem ipsum LorLorem ipsum em ipsum", backgroundImage: `url(${image1})`, image: image21 },
        { id: 2, width: '60%', zIndex: 1, heading: "Swaad Mess", content: "Lorem ipsum Lorem ipsumLorem ipsum Lorem Lorem ipsum LorLorem ipsum em ipsum", backgroundImage: `url(${image2})`, image: image31 },
        { id: 3, width: '60%', zIndex: 1, heading: "Pinnaclecodevista", content: "Lorem ipsum Lorem ipsumLorem ipsum Lorem Lorem ipsum LorLorem ipsum em ipsum", backgroundImage: `url(${image3})`, image: image12 },
    ];

    return (
        <div style={{ maxWidth: "1600px" }}>
          
            <div className='container mt-4'>
                <h1 className="display-6 fw-bold text-center" style={{ color: "#107898" }}>Portfolio</h1>

                <div className="gallery mt-4">
                    <div className="gallerycarousel">
                        {cards.map((card) => (
                            <Card
                                key={card.id}
                                className="carousel-card"
                                style={{ width: card.width, zIndex: card.zIndex, backgroundImage: card.backgroundImage }}
                            >
                                {(isMobileView || selectedCard === card.id) && (
                                    <Card.Body className='container'>
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className='row justify-content-center'>
                                                    <div className='col-md-6 justify-content-center' >
                                                        <img src={card.image} alt="card" className="card-img-top" />
                                                    </div>
                                                </div>
                                                <div className='container pb-0 mb-0'>
                                                    <div className='row pt-3'>
                                                        <div className='col-md-8 text-wrap text-start'>
                                                            <h3 className='card-title' >{card.heading}</h3>
                                                            <p className='card-text' >{card.content}</p>
                                                        </div>
                                                        <div className='col-md-4 d-flex justify-content-center align-items-center'>
                                                            <button type='button' className='btn btn-light rounded-pill'>learn more</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                )}
                            </Card>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortfolioCarousel;
