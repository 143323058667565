import React, { useEffect, useState } from "react";
import usePasswordGenerator from "../hooks/usePasswordGenerator";
import PasswordChecker from "./PasswordChecker";
import "../style/Password.css";
import Navbar from "./Navbar";
const PasswordGenerator = () => {
  const [length, setLength] = useState(0);
  const [checkboxData, setCheckboxData] = useState([
    { title: "Uppercase", state: true },
    { title: "Lowercase", state: false },
    { title: "Numbers", state: false },
    { title: "Symbols", state: false },
  ]);

  // accoredance data
  const accordionData = [
    {
      title: "How does the password generator work?",
      content: "The Inphynous password generator creates random passwords based on parameters set by you. Parameters include password length, whether the password should be easy to say or read, and whether the password should have uppercase letters, lowercase letters, numbers, and symbols. The password generated, based on the user's parameters, is then checked against the zxcvbn library – a standard in evaluating password security – to see how strong the password you generate is."
    },
    {
      title: "What is Inphynous?",
      content: "Inphynous is a password manager that stores encrypted passwords online. The service uses a freemium pricing model, where a free tier offers a significant amount of functionality, and a premium tier adds more features, such as mobile phone apps, multifactor authentication, and support for YubiKey."
    },
    {
      title: "How secure is Inphynous?",
      content: "Inphynous uses AES-256 bit encryption with PBKDF2 SHA-256 and salted hashes to ensure complete security in the cloud. Your data is encrypted and decrypted at the device level. The vault password never leaves the device and the data stored in your vault is kept secret, even from Inphynous."
    },
    {
      title: "How do I recover my Inphynous account?",
      content: "If you forgot your master password, you can use the account recovery process through a trusted device. You can use biometric login (fingerprint or face recognition) if previously enabled. Alternatively, you can use a password hint or account recovery with SMS."
    },
    {
      title: "What platforms does Inphynous support?",
      content: "Inphynous is available on multiple platforms including Windows, Mac, Linux, Android, and iOS. It also offers browser extensions for Chrome, Firefox, Safari, Edge, and Opera."
    }
  ];
  const [copy, setCopy] = useState(false);

// toggleAccordion
const [openAccordion, setOpenAccordion] = useState(null);

const handleToggle = (index) => {
  setOpenAccordion(openAccordion === index ? null : index);
};

  // function to handle checkbox
  const handleCheckboxChange = (i) => {
    const upadteCheckboxData = [...checkboxData];
    upadteCheckboxData[i].state = !upadteCheckboxData[i].state;
    setCheckboxData(upadteCheckboxData);
  };

  // handle copy
  const handleCopy = () => {
    navigator.clipboard.writeText(password);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };
  // useEffect to automatically generate password
  useEffect(() => {
    generatePassword(checkboxData, length);
  }, [checkboxData, length]);
  const { password, errorMessage, generatePassword } = usePasswordGenerator();
  return (
    <div className=" main-generator-container">
      <div className='gb'>
        <div className='sticky-top topn d-none d-md-block '>
          <Navbar />
        </div>

        <div className='container text-light' >
          <div className='pt-5 d-none d-md-block'>
            <div className='row pt-5'>
              <div className='col-lg-6 mt-2'>
                <h1 className='display-4 fw-bold'>One Password Away from Total Security</h1>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-7 text-wrap'>
                <p style={{ fontSize: "22px" }}>Effortlessly Generate, Save, and Autofill Passwords on All Your Devices with LastPass.
</p>
              </div>
            </div>
            <div className='row mt-3 pb-4'>
              <div className='col-lg-6'>
                <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center' }}>
                  Get Started
                </button>
              </div>
            </div>
          </div>
          <div className=' d-block d-md-none'>
            <div className='row pt-4'>
              <div className='col-lg-6 mt-2'>
                <h1 className='display-4 fw-bold'>One Password Away from Total Security</h1>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-7 text-wrap'>
                <p style={{ fontSize: "22px" }}>Effortlessly Generate, Save, and Autofill Passwords on All Your Devices with LastPass.</p>
              </div>
            </div>
            <div className='row mt-3 pb-4'>
              <div className='col-lg-6'>
                <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center' }}>
                  Get Started
                </button>
              </div>
            </div>
          </div>


        </div>
      </div>
      <div className="container mt-5">
      <div className="text-center">
      <h2>
        Instantly generate a secure, random password <br />
       <span className="password-generate-heading"> with the Inphynous online tool</span>
      </h2>
      <p className="mt-4">
        Go beyond online generators with Inphynous Premium. No matter what device
        or app you’re using, all your passwords are automatically generated,
        saved and synced – wherever you go.
      </p>
      </div>
      </div>
      <div className=" mt-4" style={{backgroundColor:'#F4F4F4'}}>
      <div className=" container  text-center d-flex justify-content-center py-5"> <div className="col-8 bg-white password-generator">
         
        <h1 className="font-weight-bold">Secure Password <span  style={{'color':'rgb(16, 120, 152)'}}>Generator</span></h1>
        {/*Text and copy button  */}
        {password && (
          <div className="text-copy row text-center mt-5">
            <h3 className="text-copy col-md-6 col-sm-6" style={{'color':'rgb(16, 120, 152)'}}>
              {password}
            </h3>
            <div className="col-md-6 col-sm-6 justify-content-end">
            <button
              className=" btn px-5"
              onClick={handleCopy}
              style={{'backgroundColor':'rgb(16, 120, 152)',color:'white'}}
            >
              {copy ? "Copied" : "Copy"}
            </button>
            </div>
          </div>
        )}

        {/* character */}
        <div className="character row text-center mt-4 flex-column justify-content-center align-content-center">
          <div className="character-text col-md-6">
            <span className="">Character Length :-</span>
            <span className="font-weight-bold">{length}</span>
          </div>
          <div className="character-slider  col-md-6">
            <input
              type="range"
              min={0}
              max={20}
              value={length}
              onChange={(e) => setLength(e.target.value)}
              style={{ width: "100%" }}
            />
          </div>
        </div>

        {/* checkbox */}
        <div className="checkbox row mt-4">
          {checkboxData.map((checkbox, index) => (
            <div
              key={index}
              className="col-md-4 offset-2 col-sm-6 p-3 d-flex align-content-center"
            >
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={() => handleCheckboxChange(index)}
                  checked={checkbox.state}
                />
                <label className="form-check-label">{checkbox.title}</label>
              </div>
            </div>
          ))}
        </div>

        {/* strength */}
        <PasswordChecker password={password} />

        {/* Error Message */}
        <div className="row mt-3 py-2">
          <div className="col-md-12 text-center text-danger font-weight-bold">
            {errorMessage && <div className="error">{errorMessage}</div>}
          </div>
        </div>
      </div>
      </div></div>
      {/* How to make strong password */}
      <div className="container my-5 bg-white">
      <h2 className="text-center mb-4" style={{'color':'rgb(16, 120, 152)'}}>How can you protect your passwords?</h2>
      <h5 className="text-center mb-4 ">
        Phishing, stolen credentials, and human error challenge your password security. Take action and improve your defense against them.
      </h5>
      <div className="d-flex justify-content-center">
        <div className="col-md-12">
          <ul className="list-unstyled">
            <li className="mb-3 d-flex">
              <i class="bi bi-arrow-right-circle mx-2 text-primary"></i>
              With the Inphynous built-in password generator you don’t need to fuss with thinking of new passwords. Inphynous will generate a unique password for each account you create.
            </li>
            <li className="mb-3  d-flex">
            <i class="bi bi-arrow-right-circle mx-2 text-primary"></i>
              Make sure your passwords are at least 12 characters long and contain letters, numbers, and special characters.
            </li>
            <li className="mb-3 d-flex">
            <i class="bi bi-arrow-right-circle mx-2 text-primary"></i>
              Don't use any personally identifiable information in your passwords.
            </li>
            <li className="mb-3 d-flex">
            <i class="bi bi-arrow-right-circle mx-2 text-primary"></i>
              Avoid password reuse with the security dashboard, which alerts you to take proactive action when you've reused a password or created a weak one.
            </li>
            <li className="mb-3 d-flex">
            <i class="bi bi-arrow-right-circle mx-2 text-primary"></i>
              When you create a password on your own, use random characters, but don't follow easy-to-recognize patterns – e.g. "qwert" or "12345."
            </li>
            <li className="mb-3 d-flex">
            <i class="bi bi-arrow-right-circle mx-2 text-primary"></i>
              Never share your passwords via email or text message. Share your sensitive information with friends and family through Inphynous' secure password sharing.
            </li>
            <li className="mb-3 d-flex">
            <i class="bi bi-arrow-right-circle mx-2 text-primary"></i>
              Avoid using similar passwords that change only a single word or character.
            </li>
            <li className="mb-3 d-flex">
            <i class="bi bi-arrow-right-circle mx-2 text-primary"></i>
              Update passwords after every three months.
            </li>
            <li className="mb-3 d-flex">
            <i class="bi bi-arrow-right-circle mx-2 text-primary"></i>
              Use a password manager like Inphynous to save your passwords, it keeps your information protected from attacks or snooping.
            </li>
          </ul>
        </div>
      </div>
    </div>


          <div className="container px-3">
      {/* faq */}
      <section className="mt-5">
     
          <div id="accordion" className="px-md-4">
      {accordionData.map((item, index) => (
        <div className=" card-faqs  mb-3" key={index}>
          <div className="" id={`heading${index}`}>
            <h6
              className="mb-0 accordion-header p-3"
              data-toggle="collapse"
              data-target={`#collapse${index}`}
              aria-expanded={openAccordion === index}
              aria-controls={`collapse${index}`}
              onClick={() => handleToggle(index)}
            >
              {item.title}
              <span
                className="icon"
                style={{
                  padding: '0 5px',
                  marginLeft: '10px'
                }}
              >
                {openAccordion === index ? '-' : '+'}
              </span>
            </h6>
          </div>
          <div
            id={`collapse${index}`}
            className={`collapse ${openAccordion === index ? 'show' : ''}`}
            aria-labelledby={`heading${index}`}
            data-parent="#accordion"
          >
            <div className="card-body-faq px-3 py-2">
              {item.content}
            </div>
          </div>
        </div>
      ))}
    </div>
      </section>
      </div>
    </div>
  );
};

export default PasswordGenerator;
