import React, { useState, useRef } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { IoMdReorder, IoMdClose, IoMdArrowDropdown } from "react-icons/io";
import { NavLink } from 'react-router-dom';
import '../style/Navbar.css';
import Logo1 from '../assets/ITSWhite021.png';
import Home from './Home';
import About from './About';
import Resources from './Resources';
import Services from './Services';
import Career from './Career';
import Subsidiaries from './Subsidiaries';
import ContactUs from './ContactUs';

const Navbar2 = () => {
  const [activeLink, setActiveLink] = useState(null);
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);
  const [showContent, setShowContent] = useState(false);
  const [rotateArrow, setRotateArrow] = useState(false);

  const contentRef = useRef(null);

  const handleNavLinkClick = (link) => {
    setActiveLink(link);
    setShowContent(prevState => {
      if (link === activeLink) {
        return !prevState;
      } else {
        return true;
      }
    });
    setRotateArrow(!rotateArrow);
  };

  const toggleNavbar = () => {

    setNavbarCollapsed(!navbarCollapsed);
    setActiveLink(null);

  };
  const closeNavbar = () => {
    setNavbarCollapsed(!navbarCollapsed);
  };
  const closeContentContainer = () => {
    setShowContent(false);
    setActiveLink(null);
    setRotateArrow(false);
  };

  return (
    <div className='sticky-top'>
      <Navbar className="gradient_background  " expand="lg" variant="dark">
        <Container>
          <Navbar.Brand href="/">
            <img src={Logo1} alt="Image" style={{ width: '100%', height: 'auto' }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar}>
            {navbarCollapsed ? <IoMdReorder size={24} color="white" /> : <IoMdClose size={24} color="white" />}
          </Navbar.Toggle>
          {!navbarCollapsed && (
            <Navbar.Collapse id="basic-navbar-nav" className={!navbarCollapsed ? 'show' : ''}>
              <Nav className="ms-auto mt-1">
                <NavLink
                  className={`Navlink ${activeLink === 'home' ? 'active' : ''}`}
                  to="/"
                  onClick={() => handleNavLinkClick('home')}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Home</span>
                    <IoMdArrowDropdown size={24} color="white" style={{ transform: rotateArrow && activeLink === 'home' ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                  </div>
                  <div className="content-container" style={{ display: showContent && activeLink === 'home' ? 'block' : 'none' }}>
                    {activeLink === 'home' && <Home closeNavbar={closeNavbar} closeContentContainer={closeContentContainer} />}
                  </div>
                </NavLink>

                <NavLink
                  className={`Navlink ${activeLink === 'about' ? 'active' : ''}`}
                  to="/aboutus"
                  onClick={() => handleNavLinkClick('about')}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>About</span>
                    <IoMdArrowDropdown size={24} color="white" style={{ transform: rotateArrow && activeLink === 'about' ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                  </div>
                  <div className="content-container" style={{ display: showContent && activeLink === 'about' ? 'block' : 'none' }}>
                    {activeLink === 'about' && <About closeNavbar={closeNavbar} closeContentContainer={closeContentContainer} />}
                  </div>
                </NavLink>

                <NavLink
                  className={`Navlink ${activeLink === 'services' ? 'active' : ''}`}
                  to="/services1"
                  onClick={() => handleNavLinkClick('services')}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Services</span>
                    <IoMdArrowDropdown size={24} color="white" style={{ transform: rotateArrow && activeLink === 'services' ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                  </div>
                  <div className="content-container" style={{ display: showContent && activeLink === 'services' ? 'block' : 'none' }}>
                    {activeLink === 'services' && <Services closeNavbar={closeNavbar} closeContentContainer={closeContentContainer} />}
                  </div>
                </NavLink>

                <NavLink
                  className={`Navlink ${activeLink === 'career' ? 'active' : ''}`}
                  to="#career1"
                  onClick={() => handleNavLinkClick('career')}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Career</span>
                    <IoMdArrowDropdown size={24} color="white" style={{ transform: rotateArrow && activeLink === 'career' ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                  </div>
                  <div className="content-container" style={{ display: showContent && activeLink === 'career' ? 'block' : 'none' }}>
                    {activeLink === 'career' && <Career closeNavbar={closeNavbar} closeContentContainer={closeContentContainer} />}
                  </div>
                </NavLink>

                <NavLink
                  className={`Navlink ${activeLink === 'subsidiaries' ? 'active' : ''}`}
                  to="#subdiaries" //subsidiaries1
                  onClick={() => handleNavLinkClick('subsidiaries')}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Subsidiaries</span>
                    <IoMdArrowDropdown size={24} color="white" style={{ transform: rotateArrow && activeLink === 'subsidiaries' ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                  </div>
                  <div className="content-container" style={{ display: showContent && activeLink === 'subsidiaries' ? 'block' : 'none' }}>
                    {activeLink === 'subsidiaries' && <Subsidiaries closeNavbar={closeNavbar} closeContentContainer={closeContentContainer} />}
                  </div>
                </NavLink>

                <NavLink
                  className={`Navlink ${activeLink === 'resources' ? 'active' : ''}`}
                   to="#resources1" //resources1
                  onClick={() => handleNavLinkClick('resources')}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Resources</span>
                    <IoMdArrowDropdown size={24} color="white" style={{ transform: rotateArrow && activeLink === 'resources' ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                  </div>
                  <div className="content-container" style={{ display: showContent && activeLink === 'resources' ? 'block' : 'none' }}>
                    {activeLink === 'resources' && <Resources closeNavbar={closeNavbar} closeContentContainer={closeContentContainer} />}
                  </div>
                </NavLink>

              </Nav>

              <Nav>
                <button type="button" className="btn btn-sm btn-light rounded-pill mb-4" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center' }}>
                  <NavLink to="/contactus" style={{ fontSize: 'large', color: '#000', margin: 'auto', textDecoration: 'none' }}>Contact Us</NavLink>
                </button>
              </Nav>
            </Navbar.Collapse>
          )}

        </Container>
      </Navbar>
    </div>
  );
};

export default Navbar2;

