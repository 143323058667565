import React from "react";

const InternshipBenifits = () => {
 
  return (
   <div className="container px-4">
          <h1 className='display-6 fw-bold text-center'>What You Gain with <span style={{ color: "#107898" }}>Our Internship</span> </h1>
<div className="row">
<div className="col-md-6 pt-4 px-md-4 px-2">
    <div className="card card-benifits text-white py-2 px-3">
        <div>
        <h5 className="" >Immediate Practical Experience:</h5>
        <p className="mb-0">Engage in real projects from the start.
        </p> 
        </div>
    </div>
</div>
<div className="col-md-6 px-md-4 px-2 pt-md-4 pt-3 ">
    <div className="card card-benifits text-white  py-2 px-3">
        <div>
        
        <h5 className="" >Professional Networking:</h5>
        <p className="mb-0">Build connections with industry experts and mentors.
        </p> 
     </div>
    </div>
</div>
</div>
<div className="row">
<div className="col-md-6 px-md-4 px-2 pt-3">
    <div className="card card-benifits text-white py-2 px-3">
        <div>
        <h5 className="" >Resume Building:</h5>
        <p className="mb-0">Enhance your resume with substantial, hands-on experience.
        </p> 
        </div>
    </div>
</div>
<div className="col-md-6 px-md-4 px-2 pt-3 ">
    <div className="card card-benifits text-white py-2 px-3">
        <div>       
        <h5 className="" >Live Client Projects:</h5>
        <p className="mb-0"> Work directly with actual clients.</p>     
         </div>
    </div>
</div>
</div>
<div className="row ">
<div className="col-md-6 px-md-4 px-2 pt-3 ">
    <div className="card card-benifits text-white text-white  py-2 px-3">
        <div> 
        <h5 className="" >Expert Mentorship:</h5>
        <p className="mb-0">Learn from experienced industry professionals.</p>	
        </div>
    </div>
</div>
<div className="col-md-6 px-md-4 px-2 pt-3 ">
    <div className="card card-benifits text-white  py-2 px-3">
        <div>
        <h5 className="" >Job Placement Support:</h5>
        <p className="mb-0">Receive assistance in securing a job post-internship.</p>	 
        </div>
    </div>
</div>
</div>
<div className="row">
<div className="col-md-6 px-md-4 px-2 pt-3">
    <div className="card card-benifits text-white  py-2 px-3">
        <div>
        <h5 className="" >Real-World Skills:</h5>
        <p className="mb-0">Apply your knowledge in practical, impactful scenarios.</p>
	 
        </div>
    </div>
</div>
<div className="col-md-6 px-md-4 px-2 pt-3">
    <div className="card card-benifits text-white py-2 px-3">
        <div>
        <h5 className="" >Cost-Efficient Learning: </h5>
        <p className="mb-0">Save money by bypassing expensive courses.</p>
        </div>
    </div>
</div>
</div>
<div className="row justify-content-center">
<div className="col-md-6 px-md-4 px-2 pt-3">
    <div className="card card-benifits text-white py-2 px-3">
        <div>
        <h5 className="" >Career Advancement: </h5>
        <p className="mb-0">Develop confidence and leadership through real-world challenges.</p>
        </div>
    </div>
</div>
</div>
   </div>
  );
};

export default InternshipBenifits;
