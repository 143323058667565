import React from 'react';
import '../style/Service.css';
import simg2 from '../assets/ITsolutions.png';
const ItSolution = () => {

  return (
    <div className='sbg'>
      <div className='container' >
        <div className='row' style={{ fontSize: "1rem" }}>
          <div className='col-md-5'>
            <div className='row mt-3'>
              <div className='col-md mt-4 pb-4'>
                <p className='text-light d-flex '>Mobile App Development</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md pb-4'>
                <p className='text-light d-flex '>Cloud Computing-AWS</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md pb-4'>
                <p className='text-light d-flex '>Chat Bot Development</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md pb-4'>
                <p className='text-light d-flex '>Data Analytics & Bussiness Intelligence</p>
              </div>
            </div>
            <div className='row mb-0 pb-0'>
              <div className='col-md pb-4'>
                <p className='text-light d-flex '>Artificial Intelligence (AI)</p>
              </div>
            </div>
          </div>
          <div className='col-md-5 mt-3 d-none d-md-block' style={{ position: "relative" }}>
            <img src={simg2} alt="Image 1" className="img-fluid" style={{ position: 'absolute', bottom: 0 }} />
          </div>
          <div className='col-md-9 mt-3 d-md-none order-md-2 d-block' >
            <img src={simg2} alt="Image 1" className="img-fluid" style={{ bottom: 0 }} />
          </div>
        </div>
      </div>
    </div>

  );
};

export default ItSolution;
