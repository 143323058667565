import React from 'react';
import '../style/Home.css';
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { HashLink } from 'react-router-hash-link';

const Home = (props) => {
  const scrollToElement = (el) => {
    const yOffset = document.querySelector('.navbard').offsetHeight + 0;
    const y = el.getBoundingClientRect().top + window.pageYOffset - yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
    closeContnet();
  }

  const scrollToElement1 = (el) => {
    const yOffset = document.querySelector('.navbar').offsetHeight + 0;
    const y = el.getBoundingClientRect().top + window.pageYOffset - yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
    closeNavbarAndContainer();
  };


  const closeNavbarAndContainer = () => {
    props.closeNavbar();
    props.closeContentContainer();
  };
  const closeContnet = () => {
    props.closeContentContainer();

  }


  return (
    <div className='bg'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-3 mt-4 d-none d-md-block'>
            <HashLink to="/#wwd"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement(el)}
            >
              What we do <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
          <div className='col-md-3 mt-4 d-block d-md-none'>
            <HashLink to="/#wwd"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement1(el)}
              onClick={closeNavbarAndContainer}

            >
              What we do <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 mt-3 d-none d-md-block'>
            <HashLink to="/#services"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement(el)}
            >
              Services <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
          <div className='col-md-3 mt-3 d-block d-md-none'>
            <HashLink to="/#services"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement1(el)}
              onClick={closeNavbarAndContainer}

            >
              Services <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 mt-3 d-none d-md-block'>
            <HashLink to="/#portfolio"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement(el)}
            >
              Portfolio<span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
          <div className='col-md-3 mt-3 d-block d-md-none'>
            <HashLink to="/#portfolio"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement1(el)}
              onClick={closeNavbarAndContainer}

            >
              Portfolio <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 mt-3 d-none d-md-block'>
            <HashLink to="/#customer"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement(el)}
            >
              Our Customers <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
          <div className='col-md-3 mt-3 d-block d-md-none'>
            <HashLink to="/#customer"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement1(el)}
              onClick={closeNavbarAndContainer}

            >
              Our Customers <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 mt-3 d-none d-md-block'>
            <HashLink to="/#research"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement(el)}
            >
              Research & Innovation <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
          <div className='col-md-3 mt-3 d-block d-md-none mb-3'>
            <HashLink to="/#research"
              className='border-bottom border-secondary text-secondary d-flex pb-1 text-decoration-none'
              scroll={(el) => scrollToElement1(el)}
              onClick={closeNavbarAndContainer}

            >
              Research & Innovation <span className="icon"><MdOutlineArrowForwardIos /></span>
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
