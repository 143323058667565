import React from 'react';
import '../style/Services1.css';
import { Link } from 'react-router-dom';

export default function ServiceGoal() {

  return (
   <div className='container py-5'>
<div className='row'>
    <div className='col-md-5 d-flex align-items-center border-end border-5 border-dark'>
    <h1 className='display-6 fw-bold'>Achieve Your <span className='c-blue-green'>Goals </span></h1>
    </div>
    <div className='col-md-7'>
    <div className='row'>
        <div className='px-4'>
            <h3 className='c-blue-green'>Increase Sales</h3>
            <p className='text-18 '><span className='fw-bold'>Drive More Revenue: </span> Our sales strategies are designed to connect you with the right
            customers, maximizing your revenue potential </p>
            <button className='text-18 btn btn-dark rounded-pill text-light '><Link className='text-decoration-none text-white'>Boost Your Sales </Link> </button>
            </div>
            <div className='px-4 pt-3'>
            <h3 className='c-blue-green'>Optimize ROI</h3>
            <p className='text-18 '><span className='fw-bold'>Effective Investments: </span>  We focus on achieving measurable results. Our data-driven 
            approach ensures you get the best return on your marketing investments. </p>
            <button className='text-18 btn btn-dark rounded-pill text-light '><Link className='text-decoration-none text-white'>Maximize Your ROI </Link> </button>
            </div>
            <div className='px-4 pt-3'>
            <h3 className='c-blue-green'>Best Practices</h3>
            <p className='text-18 '><span className='fw-bold'>Continual Improvement: </span>  We continually refine our strategies based on performance 
            data to ensure we’re delivering the best results possible. </p>
            <button className='text-18 btn btn-dark rounded-pill text-light '><Link className='text-decoration-none text-white'>See Our Methods </Link> </button>
            </div>
</div>
    </div>

</div>
<div className='row pt-5'>
    <div className='col-md-5 d-flex align-items-center border-end border-5 border-dark'>
    <h1 className='display-6 fw-bold'>Discover Our <span className='c-blue-green'> Difference </span></h1>
    </div>
    <div className='col-md-7'>
    <div className='row'>
        <div className='px-4'>
            <h3 className='c-blue-green'>Fast and Efficient</h3>
            <p className='text-18 '><span className='fw-bold'>Timely Results: </span>  Our team is dedicated to delivering high-quality results quickly, 
            meeting your business needs effectively</p>
            <button className='text-18 btn btn-dark rounded-pill text-light '><Link className='text-decoration-none text-white'>Meet Our Team </Link> </button>
            </div>
            <div className='px-4 pt-3'>
            <h3 className='c-blue-green'> Comprehensive Solutions</h3>
            <p className='text-18 '><span className='fw-bold'>All-in-One Service: </span> We provide a full range of digital services, ensuring all your 
            needs are met under one roof </p>
            <button className='text-18 btn btn-dark rounded-pill text-light '><Link className='text-decoration-none text-white'> Explore Our Services</Link> </button>
            </div>
            <div className='px-4 pt-3'>
            <h3 className='c-blue-green'>Transparent and Collaborative</h3>
            <p className='text-18 '><span className='fw-bold'>Clear Communication: </span>  We work closely with you throughout the process, maintaining
            open communication and transparency at every step.</p>
            <button className='text-18 btn btn-dark rounded-pill text-light '><Link className='text-decoration-none text-white'>Understand Our Process </Link> </button>
            </div>

</div>
    </div>

</div>
<div className='pt-5 mt-3'>
<h1 className='display-6 fw-bold text-center'>Let's Work <span className='c-blue-green'>Together  </span></h1>
<p className='text-18 text-center pt-2'>Partner with Us Ready to grow your business? Connect with our experts to discuss how we can help you achieve your goals.</p>
<div className='text-center'>  
 <button className='text-18 btn btn-dark rounded-pill text-light '><Link className='text-decoration-none text-white'>Get Started Today    </Link> </button>
 </div>
</div>
   </div>
  );
}
