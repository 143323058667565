import React from 'react';

const InternshipSuccess = () => {
  return (
      <div className='container'>
      <h1 className=' display-6 fw-bold text-center pb-5'> Your Path to<span style={{ color: "#107898" }}> Success</span> </h1>
        <div className='row px-5 px-md-5 '>
          <div className='col-md me-md-4 mb-2 py-4 internship-numbers1 '>
          <h4 className='text-center mx-5'>Internal Job Placement</h4>
          <p className='text-center px-1 mb-0 pt-2'>Opportunities to secure full-time positions within our company.</p>
          </div>
          <div className='col-md mx-md-4 mb-2 py-4 internship-numbers1 '>
          <h4 className='text-center mx-5'>External Job Assistance</h4>
          <p className='text-center px-1 mb-0 pt-2'>Complete support to help you find roles at other leading companies. </p>
          </div>
          <div className='col-md ms-md-4 mb-2 py-4 internship-numbers1 '>
          <h4 className='text-center mx-3'>Ongoing Professional Development</h4>
          <p className='text-center px-1 mb-0 pt-2'>Continuous growth and support even after the internship.</p>
          </div>
          
        </div>
      </div>
      
  );
}

export default InternshipSuccess;
