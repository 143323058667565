import React from 'react';

const Timeline = ({ events }) => {
    return (
        <div className="container">
        <h1 className=' display-6 fw-bold text-center pb-5'> Internship <span style={{ color: "#107898" }}> Structure</span> </h1>
            <div className="row pt-md-3">
                <div className="col-md-12">
                    <ul className="timeline text-white">
                        {events.map((event, index) => (
                            <li key={index} className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}>
                                <div className="timeline-badge"><h5 className='pt-1 mb-0'>{event.id}</h5> </div>
                                <div className="timeline-panel rounded-3">
                                    <div className="timeline-heading">
                                        <h5 className="timeline-title">{event.title}</h5>
                                    </div>
                                    <div className="timeline-body">
                                        <p className='mb-0'>{event.description}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Timeline;
