import React, { useState, useEffect } from 'react';
import '../style/AboutUs.css';
import aimg1 from '../assets/ARectangle1.png';
import aimg2 from '../assets/ARectangle2.png';
import aimg3 from '../assets/ARectangle3.png';
import aimg4 from '../assets/ARectangle4.png';
import aimg5 from '../assets/ARectangle5.png';
import aimg6 from '../assets/ARectangle6.png';
import aimg7 from '../assets/ARectangle7.png';
import aimg8 from '../assets/ARectangle8.png';
import aimg9 from '../assets/ITSlogosBlack.png';
import aimg10 from '../assets/Frame27.png';
import aimg11 from '../assets/Rectangle33.png';
import aimg12 from '../assets/Rectangle34.png';
import aimg13 from '../assets/Rectangle35.png';
import ItSolution from './ItSolution';
import WebSolution from './WebSolution';
import AppDev from './AppDev';
import DesignSol from './DesignSol';
import MarketSol from './MarketSol';
import { SlArrowRightCircle, SlArrowLeftCircle, SlArrowDownCircle, SlArrowUpCircle } from "react-icons/sl";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Gallery from './Gallery';
import img8 from '../assets/Rectangle13.png';
import WhatsNew from './WhatsNew';
import OurCustomer from './OurCustomer';
import Slideshow from './Slideshow';
import Navbar from './Navbar';
AOS.init();
export default function AboutUs() {
  const [activeComponent, setActiveComponent] = useState("itsolution");

  const handleComponentClick = (componentName) => {
    setActiveComponent(componentName);
  };

  const handleNext = () => {
    switch (activeComponent) {
      case "itsolution":
        setActiveComponent("websolution");
        break;
      case "websolution":
        setActiveComponent("appdev");
        break;
      case "appdev":
        setActiveComponent("designsol");
        break;
      case "designsol":
        setActiveComponent("marketsol");
        break;
      case "marketsol":
        setActiveComponent("itsolution");
        break;
      default:
        setActiveComponent("itsolution");
        break;
    }
  };

  const handlePrevious = () => {
    switch (activeComponent) {
      case "itsolution":
        setActiveComponent("marketsol");
        break;
      case "websolution":
        setActiveComponent("itsolution");
        break;
      case "appdev":
        setActiveComponent("websolution");
        break;
      case "designsol":
        setActiveComponent("appdev");
        break;
      case "marketsol":
        setActiveComponent("designsol");
        break;
      default:
        setActiveComponent("itsolution");
        break;
    }
  };
  const texts = [
    { heading: 'Variety', subtext: "Our variety is our strength. It's what makes us distinct and dynamic. We celebrate the diversity of our backgrounds and viewpoints because it sparks innovation and creativity."},
    { heading: 'Fairness', subtext: "Embracing our Uniqueness, Ensuring Justness, and Cultivating Community We're dedicated to nurturing a culture of inclusiveness and impartiality, where each individual's voice is acknowledged, every viewpoint is respected, and every person feels valued and acknowledged for their individuality." },
    { heading: 'Belonging', subtext: "Join us in shaping a world where everyone has a place, where diversity flourishes, and where fairness prevails. We're committed to building a community where everyone feels at home, where diversity is not only accepted but embraced, and where equity is the cornerstone of our ethos." },
  ];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  

  const newLocal = <div className='col-5'>
    <img src={aimg9} alt="Image" className='img-fluid' />

  </div>;
  return (
    <div className='main mx-auto'>
      <div className='gb'>
        <div className='sticky-top topn d-none d-md-block'>      <Navbar />
        </div>
        <div className='container text-light'>
          <div className='pt-5 d-none d-md-block'>
            <div className='row pt-5'>
              <div className='col-lg-6 mt-2'>
                <h1 className='display-4 fw-bold'>About Inphynous.</h1>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-7 text-wrap'>
                <p style={{ fontSize: "22px" }}>Inphynous is the leading solution to help enterprises know, protect, and control
                  their data wherever it goes.</p>
              </div>
            </div>
            <div className='row mt-3 pb-4'>
              <div className='col-lg-6'>
                <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center' }}>
                  Get Started
                </button>
              </div>
            </div>
          </div>
          <div className='d-block d-md-none'>
            <div className='row pt-4'>
              <div className='col-lg-6 mt-2'>
                <h1 className='display-4 fw-bold'>About Inphynous.</h1>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-7 text-wrap'>
                <p style={{ fontSize: "22px" }}>Inphynous is the leading solution to help enterprises know, protect, and control
                  their data wherever it goes.</p>
              </div>
            </div>
            <div className='row mt-3 pb-4'>
              <div className='col-lg-6'>
                <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center' }}>
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='agb1 pt-5' id='wwa'>
        <div className='container pt-3 text-wrap'>
          <h1 className='display-6 fw-bold text-center'>Who We <span style={{ color: "#107898" }}>Are</span> </h1>
          <div className='row pt-4 pb-3 position-relative justify-content-end acard' >
            <div className='col-md-8 order-2 acard1 position-absolute top-50 start-0 translate-middle-y ' >
              <div className='align-middle'>
                <h2 className="mt-4 fw-bold" style={{ color: "#107898" }}>Pioneering Technological Solutions</h2>
                <p className=" pb-3 pt-3 fw-normal" style={{ color: "#1E1E1E", fontSize: "16px", lineHeight: "21px" }}>Showcase our commitment to pushing the boundaries of innovation in the IT industry. We pride ourselves on being
                  at the forefront of technological advancements, continuously developing groundbreaking solutions that anticipate and
                  address future challenges.
                </p>
              </div>
            </div>
            <div className='col-md-5'>
              <div className='mt-3 mb-3 order-1 acard2'>
                <img src={aimg1} alt="Image" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>
          <div className='row pt-4 pb-3  position-relative  acard'>
            <div className='col-md-8 order-2 acard1 position-absolute top-50 end-0 translate-middle-y'>
              <h2 className='mt-4 fw-bold' style={{ color: "#107898" }}>Tailored Excellence for Every Client</h2>
              <p className="pb-3 pt-3 fw-normal" style={{ color: "#1E1E1E", fontSize: "16px", lineHeight: "21px" }}>n a rapidly evolving digital world, staying ahead of the curve is
                crucial. Our team of seasoned experts is constantly exploring
                new technologies and methodologies to deliver cutting-edge
                solutions that drive innovation and keep you ahead of the
                competition.</p>
            </div>
            <div className='col-md-5 order-1'>
              <div className='mt-3 mb-3 acard2'>
                <img src={aimg2} alt="Image" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>
          <div className='row pt-4 pb-3 position-relative justify-content-end acard' >
            <div className='col-md-8 order-2 acard1 position-absolute top-50 start-0 translate-middle-y ' >
              <div className='align-middle'>
                <h2 className="mt-4 fw-bold" style={{ color: "#107898" }}>Agility in Action: Driving Success at Speed</h2>
                <p className=" pb-3 pt-3 fw-normal" style={{ color: "#1E1E1E", fontSize: "16px", lineHeight: "21px" }}>Agility is more than just a buzzword for us; it's a way of life. We thrive on the fast-paced nature of the IT landscape,
                  embracing agile methodologies to deliver results with unparalleled speed and efficiency. Our adaptable approach
                  ensures that we can pivot quickly to meet evolving project requirements, delivering high-quality software solutions on
                  time and within budget.
                </p>
              </div>
            </div>
            <div className='col-md-5'>
              <div className='mt-3 mb-3 order-1 acard2'>
                <img src={aimg3} alt="Image" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>
          <div className='row pt-4 pb-3  position-relative  acard'>
            <div className='col-md-8 order-2 acard1 position-absolute top-50 end-0 translate-middle-y'>
              <h2 className='mt-4 fw-bold' style={{ color: "#107898" }}>Unleashing the Power of Diverse Expertise</h2>
              <p className="pb-3  pt-3 fw-normal" style={{ color: "#1E1E1E", fontSize: "16px", lineHeight: "21px" }}>Our team is a diverse mix of talents and backgrounds, each bringing their unique perspectives and skills to the table.
                From software developers to designers and project managers, we harness the collective power of our multidisciplinary
                team to offer comprehensive solutions that address every aspect of our clients' needs.</p>
            </div>
            <div className='col-md-5 order-1'>
              <div className='mt-3 mb-3 acard2'>
                <img src={aimg4} alt="Image" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>
          <div className='row pt-4 pb-3 position-relative justify-content-end acard' >
            <div className='col-md-8 order-2 acard1 position-absolute top-50 start-0 translate-middle-y ' >
              <div className='align-middle'>
                <h2 className="mt-4 fw-bold" style={{ color: "#107898" }}>Crafting Excellence, Every Line of Code</h2>
                <p className=" pb-3 pt-3 fw-normal" style={{ color: "#1E1E1E", fontSize: "16px", lineHeight: "21px" }}>Quality is not just a goal; it's a promise. We take pride in our meticulous attention to detail and unwavering commitment
                  to excellence in every line of code we write. Through rigorous testing, quality assurance processes, and adherence to
                  industry standards, we ensure that our software products meet the highest standards of reliability, performance, and
                  security.

                </p>
              </div>
            </div>
            <div className='col-md-5 order-1'>
              <div className='mt-3 mb-3 acard2'>
                <img src={aimg5} alt="Image" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>
          <div className='row pt-4 pb-3  position-relative  acard'>
            <div className='col-md-8 order-2 acard1 position-absolute top-50 end-0 translate-middle-y'>
              <h2 className='mt-4 fw-bold' style={{ color: "#107898" }}>Innovative Product Development</h2>
              <p className="pb-3 pt-3 fw-normal" style={{ color: "#1E1E1E", fontSize: "16px", lineHeight: "21px" }}>Beyond offering software development services, we're trailblazers in product innovation. Constantly researching and
                developing our own software solutions, we leverage our expertise to create cutting-edge products that solve real-world
                problems and drive industry transformation.</p>
            </div>
            <div className='col-md-5 order-1'>
              <div className='mt-3 mb-3 acard2'>
                <img src={aimg6} alt="Image" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>
          <div className='row pt-4 pb-3 position-relative justify-content-end acard' >
            <div className='col-md-8 order-2 acard1 position-absolute top-50 start-0 translate-middle-y ' >
              <div className='align-middle'>
                <h2 className="mt-4 fw-bold" style={{ color: "#107898" }}>Customer-Centric Product Design</h2>
                <p className=" pb-3 pt-3 fw-normal" style={{ color: "#1E1E1E", fontSize: "16px", lineHeight: "21px" }}>Central to our product development philosophy is a deep understanding of our customers' needs and pain points.
                  We place a strong emphasis on user-centric design, conducting extensive research and user testing to create intuitive,
                  user-friendly software solutions that resonate with our target audience.
                </p>
              </div>
            </div>
            <div className='col-md-5 order-1'>
              <div className='mt-3 mb-3 acard2'>
                <img src={aimg7} alt="Image" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>
          <div className='row pt-4 pb-3  position-relative  acard'>
            <div className='col-md-8 order-2 acard1 position-absolute top-50 end-0 translate-middle-y'>
              <h2 className='mt-4 fw-bold' style={{ color: "#107898" }}>Make Creativity Work For Your Brand!</h2>
              <p className="pb-3 pt-3 fw-normal" style={{ color: "#1E1E1E", fontSize: "16px", lineHeight: "21px" }}>"In conclusion, these points succinctly capture the core values and expertise that define us. At [Your Company Name],
                we're more than just a software development company - we're innovators, problem solvers, and partners in your success.
                Explore our offerings further to see how we can empower your business with cutting-edge solutions and unparalleled
                service. Welcome to a new era of possibilities with [Your Company Name]."</p>
            </div>
            <div className='col-md-5 order-1'>
              <div className='mt-3 mb-3 acard2'>
                <img src={aimg8} alt="Image" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 my-5 acard3'>
            <img src={aimg10} alt="Image" style={{ width: '100%', height: 'auto' }} />
            </div>
            <div className='col-md-6 my-auto ps-5 d-none d-md-block'>
            <h1 className='display-6 fw-bold '>Inphynous <span style={{ color: "#107898" }}>at a glance</span> </h1>
            <p>Download the one pager for an concise overview of how Seclore can help your 
enterprise prevent data theft and achieve compliance. Share this asset with 
your team and kickstart your data-centric security journey.</p>
            </div>
            <div className='col-md-6 my-auto d-block d-md-none'>
            <h1 className='display-6 fw-bold '>Inphynous <span style={{ color: "#107898" }}>at a glance</span> </h1>
            <p>Download the one pager for an concise overview of how Seclore can help your 
enterprise prevent data theft and achieve compliance. Share this asset with 
your team and kickstart your data-centric security journey.</p>
            </div>
        </div>
      </div>
      <div className='agb2'>
       <div className='container'>
      <div className='row' id='about'>
        <h1 className='display-6 mt-4 fw-bold text-center text-light'>About Us</h1>
        <div className='col-md-7'>
        <div className="mt-5 pt-2 text-light">
        <h2 className='text-start'>{texts[currentTextIndex].heading}</h2>
      <p className='text-start' style={{lineHeight:"21px"}}>{texts[currentTextIndex].subtext}</p>
    </div>
        </div>
        
      </div>
      </div>
      </div>
      <div className='container mb-5'>
        <div className='row '>
          <div className='col-md-6'>
      <img src={aimg9} alt="Image" style={{width:"60%"}}/>
      <h2 className='fw-bold'> At Inphynous Technology Solutions, 
we're passionate about technology.</h2>
<p className='col-md-8 pb-0 mb-0' style={{color:"#107898", fontSize:"17px"}}>
Since May 2020,
</p>
<p className='col-md-8 lh-sm' style={{color:"#107898", fontSize:"17px"}}>we've been dedicated to making digital solutions 
accessible and effective for businesses of all sizes.</p>   
      </div>
      </div>
      </div>
      <div className='agb3'>
      <div className='container'>
        <div className='row  '>
        <div className='col-md-4  my-3 d-block d-md-none '>
          <img src={aimg11} className=' d-block' alt="Image" style={{width:"100%"}}/>
          <h2 className='text-light pt-3 fw-bold'>Founding Story</h2>
          <p className=' pt-2' style={{color:"#4CC9F0"}}>In May 2020, amidst the pandemic's uncertainty, we saw a chance to help struggling businesses. Our journey began witha simple belief: challenges are opportunities to grow. Fueled by innovation and resilience, we set out to turn uncertainty into progress. Today, we're here to support 
businesses like yours in navigating the digital landscape and thriving in the new normal.</p>
            </div>
          <div className='col-md-4 my-3 d-block d-md-none '>
          <img src={aimg12} className='d-block' alt="Image" style={{width:"100%"}}/>
          <h2 className='text-light pt-3 fw-bold'>Vision</h2>
          <p className='pt-2' style={{color:"#4CC9F0"}}>To lead in the IT sector by 
implementing innovative solutions 
across industries, leveraging 
technology like AI to address 
emerging challenges and create a 
positive impact."</p>
            </div>
            <div className='col-md-4  my-3 d-block d-md-none '>
          <img src={aimg13} className='d-block' alt="Image" style={{width:"100%"}}/>
          <h2 className='text-light pt-3 fw-bold'>Mission</h2>
          <p className='pt-2' style={{color:"#4CC9F0"}}>To empower businesses and 
individuals with cutting-edge 
IT solutions, fostering 
growth and success in 
the digital age.</p>
            </div>
            <div className='col-md-4 border-end border-5 border-white my-3 d-none d-md-block'>
          <img src={aimg11} className='mx-auto d-block' alt="Image" style={{width:"70%"}}/>
          <h2 className='text-light mx-5 px-2 pt-3 fw-bold'>Founding Story</h2>
          <p className='mx-5 px-2 pt-2' style={{color:"#4CC9F0"}}>In May 2020, amidst the pandemic's uncertainty, we saw a chance to help struggling businesses. Our journey began witha simple belief: challenges are opportunities to grow. Fueled by innovation and resilience, we set out to turn uncertainty into progress. Today, we're here to support 
businesses like yours in navigating the digital landscape and thriving in the new normal.</p>
            </div>
          <div className='col-md-4 border-end border-5 border-white my-3 d-none d-md-block'>
          <img src={aimg12} className='mx-auto d-block' alt="Image" style={{width:"70%"}}/>
          <h2 className='text-light mx-5 px-2 pt-3 fw-bold'>Vision</h2>
          <p className='mx-5 px-2 pt-2' style={{color:"#4CC9F0"}}>To lead in the IT sector by 
implementing innovative solutions 
across industries, leveraging 
technology like AI to address 
emerging challenges and create a 
positive impact."</p>
            </div>
            <div className='col-md-4  my-3 d-none d-md-block'>
          <img src={aimg13} className='mx-auto d-block' alt="Image" style={{width:"70%"}}/>
          <h2 className='text-light mx-5 px-2 pt-3 fw-bold'>Mission</h2>
          <p className='mx-5 px-2 pt-2' style={{color:"#4CC9F0"}}>To empower businesses and 
individuals with cutting-edge 
IT solutions, fostering 
growth and success in 
the digital age.</p>
            </div>
            
        </div>
      </div>
      </div>
      <div className='container mt-5'>
      </div>
      <div className='container'>
      <h1 style={{ fontWeight: "650" }} className=''>Our Journey </h1>
      <p className='pb-2 lh-sm' style={{color:"#107898", fontSize:"17px"}}>In May 2020, amidst the COVID-19 lockdown, we identified the need for comprehensive digital solutions. Since then,
we've expanded our services beyond web development and digital marketing to include mobile app development, 
CRM and HRM systems, payment gateway integration, graphic design, chatbot implementation, and digital marketing strategies.
</p>
<p className='pb-2 lh-sm' style={{color:"#107898", fontSize:"17px"}}>Furthermore, we've fostered partnerships with businesses across various industries, enabling us to deliver tailored solutions
that meet their unique needs. Looking ahead, we're committed to pioneering advanced services and products focused on
AI and machine learning. Our goal is to develop intelligent automation solutions that drive efficiency and innovation for 
businesses of all sizes.</p>
<p className='pb-2 lh-sm' style={{color:"#107898", fontSize:"17px"}}>As we continue our journey, our dedication to innovation and customer-centricity remains unwavering. We're passionate about
empowering businesses to thrive in the digital era and shaping the future of technology together.</p>
      </div>
      <div className='gb3 mt-4'>
        <div className='container text-light'>
          <div className='row'>
            <div className='col-lg-7 mt-2'>
              <h1 className='display-4 fw-bold'>Fuel your digital growth.
                Let’s craft solutions together.</h1>
            </div>
          </div>
          <div className='row mt-3 pb-4'>
            <div className='col-lg-6'>
              <button type="button" className="btn btn-sm btn-dark rounded-pill" style={{ fontSize: 'large', padding: '10px 15px', lineHeight: '0.8', display: 'flex', alignItems: 'center' }}>
                Schedule a Meeting
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
