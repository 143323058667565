import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useInView } from 'react-intersection-observer';

const AnimatedNumbers = ({ target, label }) => {
  const [displayNumber, setDisplayNumber] = useState(0);
  const { ref, inView } = useInView({
    triggerOnce: true, 
    threshold: 0.1, 
  });

  useEffect(() => {
    if (inView) {
      let currentNumber = 0;
      const increment = target / 10; 

      const interval = setInterval(() => {
        const nextNumber = currentNumber + Math.floor(Math.random() * increment) + 1;
        if (nextNumber >= target) {
          setDisplayNumber(target);
          clearInterval(interval);
        } else {
          currentNumber = nextNumber;
          setDisplayNumber(currentNumber);
        }
      }, 50);

      return () => clearInterval(interval);
    }
  }, [inView, target]);

  return (
    <div className="text-center text-white" ref={ref}>
      <h1 className=''>{target === 60 ? `${displayNumber}M+` : `${displayNumber}%`}</h1>
      <h5>{label}</h5>
    </div>
  );
};

const InternshipItLandscape = () => {
  return (
    <div className="container text-center pt-4">
      <h1 className=' display-6 fw-bold text-center text-white pt-2'>Current IT Sector Landscape</h1>
      <div className="row pt-5 py-5 ">
        <div className="col-md-4">
          <AnimatedNumbers target={60 }  label="Job Market Expansion" />
        </div>
        <div className="col-md-4">
          <AnimatedNumbers target={85} label="Preference for Experience" />
        </div>
        <div className="col-md-4">
          <AnimatedNumbers target={10} label="Rapid Industry Growth" />
        </div>
        
      </div>
    </div>
  );
};

export default InternshipItLandscape;
