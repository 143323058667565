import React from 'react';
import {Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Resources from './components/Resources';
import Career from './components/Career';
import 'bootstrap/dist/css/bootstrap.min.css';
import Services from './components/Services';
import Subsidiaries from './components/Subsidiaries';
import Footer from './components/Footer';
import Index1 from './components/Index1';
import "@fontsource/roboto"; 
import "@fontsource/roboto/400.css"; 
import "@fontsource/roboto/400-italic.css"; 
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
import Navbar1 from './components/Navbar1';
import Services1 from './components/Services1';
import DigitalMarketing from './components/DigitalMarketing';
import Career1 from './components/Career1';
import Subsidiaries1 from './components/Subsidiaries1';
import Resources1 from './components/Resources1';
import PasswordGenerator from './components/PasswordGenerator';
import Internship from './components/Internship';
import './App.css'
function App() {
  
  return (
    
      <div>
        <div className='sticky-top d-block d-md-none'> <Navbar1/></div>
     {/* <ProgramHighlight /> */}
     {/* <SuccessStory /> */}
     {/* <Learn />
     <ToolsLearn />
     <Mentors />
     <ProgramWork />
     <Certify />
     <DesignProgram />
     <OfflineEvents />
    <Upscale /> */}
    {/* <Faq/> */}
        <Routes>
        <Route path="/" element={<Index1> </Index1>} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/career" element={<Career />} />
          <Route path="/career1" element={<Career1 />} />
          <Route path="/subsidiaries" element={<Subsidiaries />} />
          <Route path="/subsidiaries1" element={<Subsidiaries1 />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/resources1" element={<Resources1 />} />
          <Route path="/contactus" element={<ContactUs/>} />
          <Route path="/aboutus" element={<AboutUs/>} />
          <Route path="/services1" element={<Services1/>} />
          <Route path="/digitalmarketing" element={<DigitalMarketing/>} />
          <Route path="/passwordgenerator" element={<PasswordGenerator/>} />
          <Route path="/internship" element={<Internship/>} />

        </Routes>
        <Footer/>
      </div>
  );
}

export default App;
